import React, { Component } from 'react';
import initiatives_image from './images/initiatives_image.png';
import articles_image from './images/articles_image.png';
import contribute_image from './images/contribute_image.png';
import './carousel.css';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

class carousel extends Component {
  render(){
    const nextIcon = 
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-right" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M6.776 1.553a.5.5 0 0 1 .671.223l3 6a.5.5 0 0 1 0 .448l-3 6a.5.5 0 1 1-.894-.448L9.44 8 6.553 2.224a.5.5 0 0 1 .223-.671z"/>
      </svg>
    const prevIcon= 
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-compact-left" viewBox="0 0 16 16">
        <path fillRule="evenodd" d="M9.224 1.553a.5.5 0 0 1 .223.67L6.56 8l2.888 5.776a.5.5 0 1 1-.894.448l-3-6a.5.5 0 0 1 0-.448l3-6a.5.5 0 0 1 .67-.223z"/>
      </svg>
    const carousel_content = [
      {
        id: "home-carousel-initiatives",
        image: initiatives_image, 
        header: "Initiatives", 
        link: "initiatives",
        description: "Check out some of our initiatives that we have executed throughout the years! Stay tuned to our social media pages to sign up for our initiatives launching across the year!",
        button_text: "Take me there!",
        left: true
      },
      {
        id: "home-carousel-articles",
        image: articles_image, 
        header: "Articles",
        link: "articles",
        description: "Curious to find out what goes on in the mind of a student? Visit our Medium page to find out more!",
        button_text: "Learn more!",
        left: false
      }, 
      {
        id: "home-carousel-contribute",
        image: contribute_image, 
        header: "Contribute", 
        link: "contribute",
        description: "Have a heart for helping others? Do you want to contribute to the betterment of society? You've come to the right place.",
        button_text: "Lend a hand!",
        left: true
      }
    ];

    const carousel_items = []
    for (var i=0; i < carousel_content.length; i++) {
      if (carousel_content[i].left){
        carousel_items.push(
          <Carousel.Item key={carousel_content[i].id} interval={5000}>
            <div className="row home-carousel-content" id={ carousel_content[i].id }>
              <div className="col-sm-6 col-12">
                <table className="home-carousel-container">
                  <div className="home-carousel-words align-middle">
                    <div className="home-carousel-header">
                      { carousel_content[i].header }
                    </div>
                    <div className="home-carousel-description">
                    { carousel_content[i].description }
                    </div>
                    <Link to= { carousel_content[i].link }>
                      <Button  className="home-carousel-button" variant="link">
                        Take me there!
                      </Button>
                    </Link>
                  </div>
                </table>
              </div>
              <div className="col-sm-6 col-12 home-carousel-image">
                <img className="align-middle" src={ carousel_content[i].image } alt={ carousel_content[i].header } width="100%"/>
              </div>
            </div>
          </Carousel.Item>
        );
      } else {
        carousel_items.push(
          <Carousel.Item key={carousel_content[i].id} interval={10000}>
            <div className="row home-carousel-content" id={ carousel_content[i].id }>
              <div className="col-sm-6 col-12">
                <table className="home-carousel-container">
                  <div className="home-carousel-words align-middle">
                    <div className="home-carousel-header">
                      { carousel_content[i].header }
                    </div>
                    <div className="home-carousel-description">
                    { carousel_content[i].description }
                    </div>
                    <Link to= { carousel_content[i].link }>
                      <Button className="home-carousel-button"  variant="link">
                        Take me there!
                      </Button>
                      </Link>
                  </div>
                </table>
              </div>
              <div className="col-sm-6 col-12 order-sm-first home-carousel-image">
                <img className="align-middle" src={ carousel_content[i].image } alt={ carousel_content[i].header }  width="100%"/>
              </div>
            </div>
          </Carousel.Item>
        );
      }
    }

    return (
      <Carousel className ="home-carousel" nextIcon = {nextIcon} prevIcon={prevIcon}>
        { carousel_items }
      </Carousel>
    );
  }
}

export default carousel;
