import React, { Component } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import PageTitle from "../common/PageTitle/PageTitle";
import PersonDisplay from './PersonDisplay';
import IntroCardLeft from './IntroCardLeft';
import IntroCardRight from './IntroCardRight';
import ContactUsBanner from './ContactUsBanner';
import "./about.css"

import PresidentImage from './img/alphonsus.jpg';
import SecretaryImage from './img/leia.jpg';
import TreasurerImage from './img/james.jpg';

import AcadImage from './img/veanna.jpg';
import StuWelImage from './img/kewei.jpg';
import HolDevImage from './img/zhangquan.jpg';

import ECGImage from './img/julian.jpg';
import PlatformsImage from './img/max.jpg';
import MarCommImage from './img/panshul.jpg';
import FBMImage from './img/chloe.jpg';

import { animateScroll as scroll } from 'react-scroll';
import { HashLink } from 'react-router-hash-link';

class AboutUsTeam extends Component {
    constructor(props) {
        scroll.scrollTo(0, {duration: 500});
        super(props);
        this.state = {props : props};
        props.check_logged_in();
    }

    componentDidMount() {
        document.title = "SGExams | About Us";
    }

    render() {
        this.ref1a = React.createRef();
        this.ref1b = React.createRef();
        this.ref1c = React.createRef();
        this.ref2a = React.createRef();
        this.ref2b = React.createRef();
        this.ref2c = React.createRef();
        this.ref3a = React.createRef();
        this.ref3b = React.createRef();
        this.ref3c = React.createRef();
        this.ref3d = React.createRef();
        const scroll1a = () => {
            this.ref1a.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll1b = () => {
            this.ref1b.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll1c = () => {
            this.ref1c.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll2a = () => {
            this.ref2a.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll2b = () => {
            this.ref2b.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll2c = () => {
            this.ref2c.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll3a = () => {
            this.ref3a.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll3b = () => {
            this.ref3b.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll3c = () => {
            this.ref3c.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        const scroll3d = () => {
            this.ref3d.current.scrollIntoView({ behavior: 'smooth', block: 'start'}) 
        }
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                    <PageTitle header = "MEET THE DEPARTMENTS" text = {"With the current new restructuring within the community, we strengthen internally as well to have a more cohesive structure within the committee.\nCheck out our different departments with the search bar below."}></PageTitle>
                <Row className="justify-content-md-center roles-row" fluid = "md">
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll1b}>
                        <PersonDisplay image={TreasurerImage} name="JAMES LIM" role = "TREASURER"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll1a}>
                        <PersonDisplay image={PresidentImage} name="ALPHONSUS KOONG BOK HUI" role = "PRESIDENT"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll1c}>
                        <PersonDisplay image={SecretaryImage} name="LEIA SOLIHAH" role = "SECRETARY"></PersonDisplay>
                    </Col>
                </Row>

                <Row className="justify-content-md-center roles-row" fluid = "md">
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll2a}>
                        <PersonDisplay image={AcadImage} name="VEANNA RANKA" role = "HEAD OF ACADEMICS"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll2b}>
                        <PersonDisplay image={StuWelImage} name="SOH KE WEI" role = "HEAD OF STUDENT WELFARE"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll2c}>
                        <PersonDisplay image={HolDevImage} name="KIAN ZHANG QUAN" role = "HEAD OF HOLISTIC DEVELOPMENT"></PersonDisplay>
                    </Col>
                </Row>

                <Row className="justify-content-md-center roles-row" fluid = "md">
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll3a}>
                        <PersonDisplay image={PlatformsImage} name="JULIAN THAM JUN JIE" role = "HEAD OF PLATFORMS"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll3b}>
                        <PersonDisplay image={MarCommImage} name="MAXIMUS ANG CHUNG FUNG" role = "HEAD OF MARKETING & COMMUNICATIONS"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll3c}>
                        <PersonDisplay image={ECGImage} name="PANSHUL" role = "HEAD OF EDUCATION & CAREER GUIDANCE"></PersonDisplay>
                    </Col>
                    <Col xs="12" md="4" lg="3" sm="6" onClick={scroll3d}>
                        <PersonDisplay image={FBMImage} name="CHLOE" role = "HEAD OF FOUR BLIND MICE"></PersonDisplay>
                    </Col>
                </Row>
                <Container className="justify-content-center d-flex p2" >
                    <HashLink className="btn-sgexams-dark about-button" to="/contribute#volunteer">Volunteer with us!</HashLink>
                </Container>
                {/* How to do paragraph breaks: wrap the speech prop in brackets to use jsx, and delimit paragraph breaks by \n */}
                <div ref={this.ref1a}>
                    <IntroCardLeft image = {PresidentImage} title = "President" name = "ALPHONSUS KOONG BOK HUI" speech = {"SGExams holds a special place in my heart. As a student navigating an uncertain future, it gave me a safe space to seek advice by drawing on the wealth of knowledge our community has to offer. I hope to pay it forward by expanding our reach and ensuring that as many students as possible benefit from our resources in the same way I did. Here's to many more impactful years ahead!"}></IntroCardLeft>
                </div>
                <div ref={this.ref1b}>
                    <IntroCardRight image = {TreasurerImage} title = "TREASURER / HEAD OF OPERATIONS" name = "JAMES LIM" speech = {"Hey! Here’s to building a more vibrant and positive student community :) being a student gets tough at times and we hope to be there for you as a safe space and a place to receive mentorship and guidance!"}></IntroCardRight>
                </div>
                <div ref={this.ref1c}>
                    <IntroCardLeft image = {SecretaryImage} title = "SECRETARY / HEAD OF INTERNAL AFFAIRS" name = "LEIA SOLIHAH" speech = {"Oversee the SGExams’ direction, review and improve organisation-wide policies/SOPs and keep abreast with societal happenings."}></IntroCardLeft>
                </div>

                <div ref={this.ref2a}>
                    <IntroCardLeft image = {AcadImage} title = "HEAD OF ACADEMICS" name = "VEANNA RANKA" speech = {"Enabling all students to make informed choices about transitions — whether into a career or a new educational phase"}></IntroCardLeft>
                </div>
                <div ref={this.ref2b}>
                    <IntroCardRight image = {StuWelImage} title = "HEAD OF STUDENT WELFARE" name = "SOH KE WEI" speech = {"To provide welfare support for students to be emotionally and mentally healthy."}></IntroCardRight>
                </div>
                <div ref={this.ref2c}>
                    <IntroCardLeft image = {HolDevImage} title = "HEAD OF HOLISTIC DEVELOPMENT" name = "KIAN ZHANG QUAN" speech = {"To provide students opportunities to develop in all non-academic areas, while connecting with students from all other schools"}></IntroCardLeft>
                </div>

                <div ref={this.ref3a}>
                    <IntroCardLeft image = {PlatformsImage} title = "HEAD OF PLATFORMS" name = "JULIAN THAM JUN JIE" speech = {"Know our community behaviour and potential reception to our projects as well as put up publicity requests by other external organizations"}></IntroCardLeft>
                </div>
                <div ref={this.ref3b}>
                    <IntroCardLeft image = {MarCommImage} title = "HEAD OF MARKETING & COMMUNICATIONS" name = "MAXIMUS ANG CHUNG FUNG" speech = {"To represent and amplify the student voice, and to inspire action from our student community through social media"}></IntroCardLeft>
                </div>
                <div ref={this.ref3c}>
                    <IntroCardRight image = {ECGImage} title = "HEAD OF EDUCATION & CAREER GUIDANCE" name = "PANSHUL" speech = {"Aims to support the various transitions through different stages of their education journey"}></IntroCardRight>
                </div>
                <div ref={this.ref3d}>
                    <IntroCardRight image = {FBMImage} title = "HEAD OF FOUR BLIND MICE" name = "CHLOE" speech = {"Aims to support polytechnic students throughout their studies and the transition to university life"}></IntroCardRight>
                </div>
                <ContactUsBanner></ContactUsBanner>
                <Footer></Footer>
            </div>
        )
    }
}

export default AboutUsTeam;
