import React, { Component } from 'react';
import './volunteer_banner.css';
import volunteer_banner_pic from './img/1.jpg';
import { Button } from 'react-bootstrap';

class Volunteer_Banner extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="volunteer" className="volunteer_bg">
                <img src={volunteer_banner_pic} className="volunteer_pic " />
                <div className="volunteer_overlay">
                    <div className="volunteer_content">
                        <div className="volunteer_banner_header">Volunteer with Us</div>
                        <p>At SGExams, there are many positions available</p>
                        <p>Find one that suits your strengths.</p>
                        <Button className="volunteer_joinbtn" href="https://tinyurl.com/sgeexamsrecruitment" variant="outline-light">Join Us</Button>
                    </div>
                </div>
            </div>
        );
    }

}

export default Volunteer_Banner;