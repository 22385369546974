import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./IntroCardRight.css"

function IntroCardRight(props) {
    return (
        <div className = "speech-block-with-image-right">
            <div className="text-right">
                <Image className = "speech-image-right" src = {props.image}></Image>    
            </div>
            <div className = "speech-block-right">
                <div className = "speech-block-inner-right">
                    <div className = "text-right speech-text-title-right">
                        <h4 className = "speech-text-title-text-right"> 
                            {props.title.toUpperCase()}
                        </h4>
                    </div>
                    {props.speech.split("\n").map(
                        (x) => 
                        <div className = "text-align-right speech-text-row-right">
                            <p className = "speech-text-text-right">{x}  </p>
                        </div>
                    )}
                    <div className = "dummy-spacing"></div>
                    <div className="text-left speech-name-right">
                        <h5 className = "speech-text-name-right">{props.name}  </h5>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default IntroCardRight;