import React from 'react';
import "./PageTitle.css"
import { Container, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

class PageTitle extends React.Component {
    render() {
        function renderIfNotNull(x, buttonLink) {
            if (x) {
                return (
                    <Row className = "justify-content-center"><Button as={Link} to={buttonLink} className="pagetitle-button">{x}</Button></Row>
                )
            }
        }
        let styles = {
            header: {
                backgroundImage: `url(${this.props.image})`
            }
        };
        return (
        <Container fluid className="pagetitle-container p-5" style = {styles.header}>
            <Row className="justify-content-center">
                <h2 className="pagetitle-header">{this.props.header}</h2>
            </Row>
            {
                this.props.text.split("\n").map((x) => <Row className="pagetitle-text justify-content-center text-center p-3">
                    {x}
                </Row>)
            }
            {
                [this.props.button].map((x) => renderIfNotNull(x, this.props.buttonLink))
            }
        </Container>
        )
    }
}

export default PageTitle;