// External Component Imports.
import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { animateScroll as scroll } from 'react-scroll'

// CSS Import.
import './contact_us.css';

class Contact_Us extends Component {
    constructor(props) {
        super(props);
        scroll.scrollTo(0, {duration: 500});
        this.state = { props : props };
        props.check_logged_in();
    }

    render(){
        document.title = "SGExams | Contact Us";
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <div className="page-container">
                        <Container fluid className="pagetitle-container p-5 contact-us-background">
                            <h2 className = "pagetitle-header">CONTACT US</h2>
                            <p className = "text-center p-3">Got a question or feedback for SGExams? Drop us an email at <a href="mailto:hello@exams.sg" className="remove-text-decoration"><span className="email">hello@exams.sg</span></a>.</p>
                        </Container>
                        <div className="d-flex justify-content-center flex-wrap channel-links">
                            <Col lg="4" md="4" sm="6" className="telegram d-flex flex-column justify-content-center align-items-center">
                                <p>The one stop place for all things SGExams!</p>
                                <a className="btn btn-telegram" href="https://t.me/SGExamsPortal">Telegram Chat Group</a>
                            </Col>

                            <Col lg="4" md="4" sm="6"  className="instagram d-flex flex-column justify-content-center align-items-center">
                                <p>Check out our latest posts and sneak peaks of our activities!</p>
                                <a className="btn btn-instagram" href="https://www.instagram.com/sgexams/">Instagram</a>
                            </Col>

                            <Col lg="4" md="4" sm="6"  className="reddit d-flex flex-column justify-content-center align-items-center">
                                <p>Join the largest discussion on SG schools and examinations!</p>
                                <a className="btn btn-reddit" href="https://www.reddit.com/r/SGExams/">Subreddit Forum</a>
                            </Col>

                            <Col lg="4" md="4" sm="6"  className="linkedin d-flex flex-column justify-content-center align-items-center">
                                <p>Keep up with our upcoming initiatives!</p>
                                <a className="btn btn-linkedin" href="https://sg.linkedin.com/company/sgexams">LinkedIn</a>
                            </Col>

                            <Col lg="4" md="4" sm="6"  className="discord d-flex flex-column justify-content-center align-items-center">
                                <p>Hang out and study with our vibrant community!</p>
                                <a className="btn btn-discord" href="https://discord.gg/sgexams">Discord Chat</a>
                            </Col>
                        </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Contact_Us;