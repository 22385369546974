import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { animateScroll as scroll } from "react-scroll";

import './library.css';
import NotesFAQ from '../common/NotesFAQ';
import StreamCard from './StreamCard';
import WhyDonate from "./WhyDonate"
import titleImage from "./images/1.png"
import img2 from './images/2.png';
import img3 from './images/3.png';
import img4 from './images/4.png';
import img5 from './images/5.png';
import img6 from './images/6.png';
import img7 from './images/7.png';
import PageTitle from '../common/PageTitle/PageTitle';

class Library extends Component {
  constructor(props) {
    super(props);
    scroll.scrollTo(0, { duration: 500 });
    this.state = {props : props};
    props.check_logged_in();
  }

  componentDidMount() {
    document.title = 'SGExams | Library';
  }

  render() {
    return (
      <div>
        <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
        <PageTitle header = "Library" text = {"Feeling generous? Have some game-changing notes that you are not using anymore? \n Share them with the community and help your fellow students."} button = {"Upload Notes"} buttonLink = "upload" image = {titleImage}></PageTitle>
        <div fluid className="notes-catalogue-banner sgexams-row">
          <div className="justify-content-center notes-catalogue-title">
            Browse our notes catalogue!
          </div>
          <div className="justify-content-center notes-catalogue-text">
            Click on your stream to find out more.
          </div>
          <Row className="justify-content-center stream-cards">
            <Col md="auto">
              <StreamCard image={img2} title="O/N Levels" link="/library/notes/ONLevel" />
            </Col>
            <Col md="auto">
              <StreamCard image={img3} title="A-Levels" link="/library/notes/ALevel" />
            </Col>
            <Col md="auto">
              <StreamCard image={img4} title="IB" link="/library/notes/IB" />
            </Col>
          </Row>
          <Row className = "pad-if-screen-small"></Row>
        </div>
        <div className="why_donate_banner sgexams-row">
          <Container fluid>
            <Row className="justify-content-center why_donate_header">
              Why should I donate my notes?
            </Row>
            <Row className="justify-content-center">
              <WhyDonate image = {img5} text = {"It's another way to give back to your community."}></WhyDonate>
              <WhyDonate image = {img6} text = {"You're helping out multiple batches of students in facilitating their learning experience."}></WhyDonate>
              <WhyDonate image = {img7} text = {"You can help play your part in preserving our environment."}></WhyDonate>
            </Row>
          </Container>
        </div>
        <NotesFAQ />
        <Footer />
      </div>
    );
  }
}

export default Library;
