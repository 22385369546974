import React, { Component } from 'react';
import './InitiativesVolunteer.css';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import PhoneImage from './images/13.png';
import { HashLink } from 'react-router-hash-link';

class InitiativesVolunteer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="initiatives-volunteer-row d-flex justify-content-between sgexams-row">
      
      <div className="initiatives-volunteer">
          <h4 >We have more exciting events and opportunities coming up for you! </h4>
          <p>Volunteer with us and make a difference in the student community.</p>  
          <HashLink className="btn-sgexams-dark about-button" to="/contribute#volunteer">Volunteer with us!</HashLink>
        {/* links to the VOLUNTEER WITH US button in about-us page bc somehow copying the css doesn't work */}
        </div>
        <Image className="phone-image disappear-if-screen-small" src={PhoneImage} alt="Partners Collaborate Image" />
    </div>
    );
  }
}
  
export default InitiativesVolunteer;