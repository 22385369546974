import React from 'react';
import './ContactUsBanner.css';

import background from './img/1.png'


function ContactUsBanner(props) {
    return (
        <div className="d-flex contact-us-block justify-content-around align-items-center p-5">
            <div className="sgexams-row">
                <h4 className="contact-us-header">Have a question?</h4>
                <h4 className="contact-us-header">Feel free to contact us!</h4>
                <p >If you are interested in finding out more about a department that you are interested in, please do not hesitate to reach out to us at our email <a className="remove-text-decoration" href="mailto:hello@exams.sg">hello@exams.sg</a>. Alternatively, you can also find us at our various social media platforms!</p>
            </div>
            <img src={background} className="disappear-if-screen-small background-image-contact"/>
        </div>
    )
}

export default ContactUsBanner;
