import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import './reset.css'
import Form from 'react-bootstrap/Form' 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'

class reset extends Component {
    constructor(props) {
        super(props);
        this.state = { email: "" , props : props};
        this.handleChange = this.handleChange.bind(this);
        scroll.scrollTo(0, {duration: 500});
        props.check_logged_in();
    }

    componentDidMount(){
      document.title = "SGExams | Reset Password";
    }

    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }

    reset = (event) => {
        let data = {'email' : this.state['email'] };
        fetch('/api/reset', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            try {
                console.log(res);
                res.json().then(value => {
                    if (res.status == 200){
                        this.setState({ success: true });
                    } else {
                        this.setState({ error_msg: value });
                    }
                })
            } catch (err){
                throw err;
            }
        });
        event.preventDefault();
    }

    render(){
        let error_msg = null;
        if (this.state.error_msg){
            error_msg = <div className="reset-error">{ this.state.error_msg }</div>;
        }
        const handleClose = () => this.setState({success: false});
        return (
            <div className="reset-page">
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <div className="page-container reset reset-background">
                    <div className="col-md-7 reset-form offset-md-5">
                        <Form onSubmit={this.reset} className="reset-form-content">
                            <h2 className='reset-header'>Password Reset</h2>
                            <div className='reset-description'>Enter the email address that you used to register. We'll send you an email with a link to reset your password.</div>
                            <div className='reset-description alert-danger'>The reset password function is currently disabled.</div>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="input-label">Email</Form.Label>
                                <Form.Control name="email"  className="reset-email-input" type="email" placeholder="examisfun@example.com" onChange={this.handleChange} required disabled/>
                            </Form.Group>
                            {error_msg}
                            <div className="reset-button-container">
                                <Button className="reset-submit col-sm-6" type="submit" disabled>Email me the link!</Button>
                            </div>
                            <Modal show={ this.state.success } onHide={ handleClose } size="sm">
                                <Modal.Body className="popup_modal">
                                    <div className="success-header">
                                        Email Sent
                                    </div>
                                    Check your inbox for further directions to reset your password.
                                </Modal.Body>
                                <Link to="login" className="link">
                                    <Modal.Footer>
                                        <div className="align-center">Got it!</div>
                                    </Modal.Footer>
                                </Link>
                            </Modal>
                        </Form>
                        <hr/>
                        <div className="sign-up">
                            For further assistance, contact us at&nbsp;
                            <a className="link" href="mailto:hello@exams.sg">hello@exams.sg</a>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default reset;
