import React from 'react';
import './contribute_feedback.css';
import { Image } from 'react-bootstrap';
import {Link} from "react-router-dom";

import FeedbackImage from './img/8.svg';

const ContributeFeedback = () => {
    return (
        <div className="d-flex contribute-feedback justify-content-between align-items-center sgexams-row">
            <div className="feedback-text">
                <h4 className="contribute-feedback-header">Feedback</h4>
                <p>Got a question or feedback for us? Drop us an email!</p>
                <Link className="btn btn-feedback-contact-us" to="/contact_us" role="button">Contact Us</Link>
            </div>
            <Image src={FeedbackImage} className="feedback-image disappear-if-screen-small"/>
        </div>
    )
}

export default ContributeFeedback;
