import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './reject_modal.css';

function RejectModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    var reason = "";
    const handleChange = (event) => {
        reason = event.target.value;
    }
    const handleSubmit = (event) => {
        fetch('/api/curation', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({action: "reject", listing_id: props.listing_id, reject_reason: reason})
        }).then(res => {
            props.reload();
            handleClose();
        });
        event.preventDefault();
    }

    return (
        <a>
            <Button className="rejectModalButton" onClick={handleShow} variant="danger" size="xs">
                REJECT
            </Button>
            <Modal show={show} onHide={handleClose} size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Reason for rejection</Modal.Title>
                </Modal.Header>
                <form onSubmit={handleSubmit}>
                    <Modal.Body className="rejectModalBody">
                        <textarea onChange={handleChange} rows="6" className="reject-textarea">

                        </textarea>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" size="xs" type="submit">Confirm</Button>
                    </Modal.Footer>
                </form>

            </Modal>
        </a>
    )
}

export default RejectModal;