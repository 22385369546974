import React from 'react';
import './partners_collaborate.css';
import PartnersCollaborateImage from './img/18.svg';
import { Container, Row, Col, Image } from 'react-bootstrap';

const PartnersCollaborate = () => {
    return (
        <div className="partners-collaborate d-flex justify-content-between sgexams-row">
            <Image className="partners-collaborate-image disappear-if-screen-small" src={PartnersCollaborateImage} alt="Partners Collaborate Image" />
            
            <div className="partners-collaborate-div">
                <h4 className = "partners-collaborate-header" >Interested to collaborate with us and reach out to the community?</h4>
                <p className = "partners-collaborate-text">Drop us an email at <a className="collab-email" href="mailto:hello@exams.sg">hello@exams.sg</a> <br/> 
                and we will get back to you!</p>
            </div>
        </div>
    )
}

export default PartnersCollaborate;