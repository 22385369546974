import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { animateScroll as scroll } from "react-scroll";
import img2 from './images/2.png';
import './submitNotesResult.css';

class SubmitNotesResult extends Component {
    constructor(props) {
        super(props);
        scroll.scrollTo(0, { duration: 500 });
        this.state = {props : props};
        props.check_logged_in();
    }
    
    componentDidMount() {
        document.title = 'SGExams | Notes Submitted';
    }

    render() {
        return (
            <div>
                <Navbar  check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                    <Row className="justify-content-center">
                        <Image className = "not-found-image" src={img2} alt="img2" fluid />
                    </Row>
                    <Row className="justify-content-center">
                        <p className = "not-found-text body-font-size">
                            Your notes have been sent to our vetters to verify :) <br/>Thank you for your contribution!
                        </p>
                    </Row>
                    <Row className="justify-content-center">
                        <Col className = "button-wrapper" md="auto" lg="2">
                            <Button className = "not-found-button" variant="sgexams-dark" href="/library">Back to Library</Button>
                        </Col>
                        <Col className = "button-wrapper" md="auto" lg="2">
                            <Button className = "not-found-button" variant="sgexams-dark" href="/upload">Donate more notes</Button>
                        </Col>
                    </Row>
                <Footer />
            </div>
        )
    }
}

export default SubmitNotesResult;
