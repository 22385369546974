import React, { Component } from 'react';
import volunteer_image from './images/volunteer_image.png';
import './volunteer.css';
import Button from 'react-bootstrap/Button';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

class registered extends Component {
  render() {
    return (
        <div className="registered container-fluid">
            <div className="row">
                <div className="col-lg-6 col content">
                    <div className="volunteer-header">
                        Volunteer with Us
                    </div>
                    <div className="volunteer-description">
                    Have a passion for the Singapore student community? Wish to make an impact on the education landscape, but don't know how? Join us as a volunteer to kickstart your journey in changing the landscape one step at a time.
                    </div>
                    <div className="row">
                        <div className="col-md-1"/>
                        <HashLink to="contribute#volunteer" className="col-md-5 col">
                            <Button className="volunteer-button" variant="link">
                                Sign me up!
                            </Button>
                        </HashLink>
                        <Link to="about_us" className="col-md-5 col">
                            <Button className="volunteer-button" variant="link">
                                Learn more
                            </Button>
                        </Link>
                        <div className="col-md-1"/>
                    </div>
                </div>
                <div className="col-lg-6 col volunteer-image">
                    <img src={ volunteer_image } alt="Volunteer Image" height="100%" />
                </div>
            </div>
        </div>
    );
  }
}

export default registered;
