// Component for the Navigation Bar

import React, { Component } from 'react'
import navbar_logo from './images/navbar_logo.png';
import navbar_dropdown_arrow from './images/navbar_dropdown_arrow.png';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';

// CSS Import
import './navbar.css';

class NavbarAccountSection extends Component {
    constructor(props) {
        super(props);
        this.state = {props : props};
        this.logout.bind(this);
    }

    logout(event){
        event.preventDefault();
        fetch('/api/logout', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(res => {
            this.state.props.check_logged_in();
        })
    }

    render() {
        if (this.props.logged_in) {
            return (
                <li className="nav-item dropdown">
                    <a className="nav-link" href="#" id="navbarAboutUsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Profile
                    <img src={navbar_dropdown_arrow} width="15px" height="15px" alt="Dropdown Icon"/>
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarAboutUsDropdown">
                        <Link className="dropdown-item" to="/profile">Profile</Link>
                        <div className="dropdown-divider"></div>
                        <Link className="dropdown-item" onClick={(event)=>this.logout(event)}>Logout</Link>
                    </div>
                </li>
            );
        } else {
            return (
                <li className="nav-item">
                    <Link className="nav-link" to="/login">Log In</Link>
                </li>
            );
        }
    }
}

export class Navbar extends Component {
    constructor(props) {
        super(props);
        this.state = {props:props};
    }

    render() {
        return (
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom">
                <Link className="navbar-brand navbar-brand-collapsed" to="/">
                    <img src={navbar_logo} width="50px" height="60px" alt="SGExams Logo"/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
    
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="nav navbar-nav navbar-custom-left flex-fill w-100 justify-content-around flex-nowrap">
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="#" id="navbarAboutUsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            About Us
                            <img src={navbar_dropdown_arrow} width="15px" height="15px" alt="Dropdown Icon"/>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarAboutUsDropdown">
                                <Link className="dropdown-item" to="/partners">Partners</Link>
                                <div className="dropdown-divider"></div>
                                <Link className="dropdown-item" to="/about_us">The Team</Link>
                            </div>
                        </li>
    
    
                        <li className="nav-item">
                            <Link className="nav-link" to="/initiatives">Initiatives</Link>
                        </li>
    
    
                        <li className="nav-item dropdown">
                            <a className="nav-link" href="/contribute" id="navbarContributeDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Contribute
                            <img src={navbar_dropdown_arrow} width="15px" height="15px" alt="Dropdown Icon"/>
                            </a>
                            <div className="dropdown-menu" aria-labelledby="navbarContributeDropdown">
                                <HashLink className="dropdown-item" to="/contribute#volunteer">Volunteer</HashLink>
                                <div className="dropdown-divider"></div>
                                <HashLink className="dropdown-item" to="/contribute#donations">Donate Notes</HashLink>
                            </div>
                        </li>
                    </ul>
    
                    <ul className="nav navbar-nav flex-fill justify-content-center navbar-center">
                        <li className="nav-item">
                            <Link className="navbar-brand" to="/">
                                <img src={navbar_logo} width="50px" height="60px" alt="SGExams Logo"/>
                            </Link>
                        </li>
                    </ul>
    
                    <ul className="nav navbar-nav navbar-custom-right flex-fill w-100 justify-content-around flex-nowrap">
                        <li className="nav-item">
                            <Link className="nav-link" to="/library">Library</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact_us">Contact Us</Link>
                        </li>
                        <NavbarAccountSection logged_in={this.state.props.logged_in} check_logged_in={this.state.props.check_logged_in}/>
                    </ul>
                </div>
            </nav>
        )
    }
}

export default Navbar;