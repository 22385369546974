import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './privacy_policy.css'

function Privacy_policy(props){
    const [show, setShow] = useState(props.privacy_policy);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return(
        <a>
            <div className="link" onClick={handleShow}>
                Privacy Policy
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body className="PP-modal-body">
                    <section>
                    <div className="PP-header">Introduction</div>
                    <p>Your privacy is important to us. The purpose of this Privacy Policy is to inform you of how r/SGExams manages personal data in accordance with the Personal Data Protection Act 2012. Please take a moment to read this Privacy Policy so that you know and understand the purposes for which we collect, use and disclose your Personal Data.</p>
                    <p>This Privacy Policy supplements but does not supersede nor replace any other consents you may have previously provided nor does it affect any rights which r/SGExams may have at law in connection with the collection, use or disclosure of your Personal Data. r/SGExams may from time to time update this Privacy Policy to ensure that this Privacy Policy is consistent with our future developments and/or any changes in legal or regulatory requirements. Subject to your rights at law, you agree to be bound by the prevailing terms of the Privacy Policy as updated from time to time on our websites. Please check regularly for updated information on the handling of your Personal Data.</p>
                    <p>r/SGExams operates the internet platform exams.sg. The Platform can be used on the web page http://exams.sg.</p>
                    <p>Users can use this platform to share study materials with other users and exchange information in discussion forums.</p>
                    <p>exams.sg collects, processes and uses users’ personal data in accordance with the content of this privacy policy as well as the Personal Data Protection Act 2012 (PDPA) and any other applicable Singaporean data protection laws.</p>
                    <p>The following paragraphs will outline what data are collected, stored and processed by r/SGExams via the exams.sg platform. </p>
                    <p>This privacy policy applies to the entire service provided by exams.sg.</p>
                    <div className="PP-header">1. Collection and processing of personal data</div>
                    <p>exams.sg collects and processes personal data of the users of exams.sg. Personal data within the meaning of these data protection regulations are individual details concerning the personal or factual circumstances as well as the user’s behavior.</p>
                    <br />
                    <p>1.1 Stock data</p>
                    <p>1.1.1 Registration via E-Mail</p>
                    <p>As part of the registration, users are asked to provide some personal information. exams.sg stores the following user data which are entered during the registration process.</p>
                    <ul>
                    <li>Email Address</li>
                    <li>A password</li>
                    <li>Profile picture (optional)</li>
                    <li>Educational Institution</li>
                    <li>Gender</li>
                    </ul>
                    <p>The legal basis for this processing of personal data is Section 13 of the Personal Data Protection Act 2012 (PDPA).</p>
                    <br />
                    <p>1.2 Usage data / Cookies / Third party applications</p>
                    <p>exams.sg collects data about the usage of the platform by its users in order to improve the platform’s usability and effectiveness and to be able to offer users a customer-tailored service.</p>
                    <p>1.2.1 Logfiles</p>
                    <p>The server logs store information submitted by the browser or by the app as users access the platform. The log files contain the following information:</p>
                    <ul>
                    <li>IP address of the computer or the Device ID of a mobile end device</li>
                    <li>Date, time, status, the amount of data transferred, and the web page from which the requested page is accessed (referrer)</li>
                    <li>Product and version information for the browser (user agent), operating system, language settings</li>
                    </ul>
                    <p>The storage of information serves to maintain and improve the platform and their security and to identify and prosecute potentially illegal activities of the users. The log files are anonymized. </p>
                    <p>The legal basis for this processing of personal data is Section 13 of the Personal Data Protection Act 2012 (PDPA).</p>
                    <br />
                    <p>1.2.2 Cookies and similar technologies</p>
                    <p>The platform uses cookies and similar technologies. Cookies and similar technologies help make the exams.sg website more user-friendly, efficient, and secure. </p>
                    <p>Cookies are small text files that are stored on a user’s computer and saved by the browser. Most of the cookies the platform uses are so-called "session cookies." They are automatically deleted after a user’s visit. Other cookies remain in the device's memory until users delete them. These cookies make it possible to recognize the user’s browser when the user next visits the site. </p>
                    <p>Users can configure their browser to inform them about the use of cookies so that they can decide on a case-by-case basis whether to accept or reject a cookie. Alternatively, the user’s browser can be configured to automatically accept cookies under certain conditions or to always reject them, or to automatically delete cookies when closing the browser. Disabling cookies may limit the functionality of this website. </p>
                    <p>We also use third party advertisements to support our site. Some of these advertisers may use technology such as cookies and web beacons when they advertise on our site, which will also send these advertisers (such as Google through the Google AdSense program) information including your IP address, your ISP , the browser you used to visit our site, and in some cases, whether you have Flash installed. This is generally used for geotargeting purposes (showing New York real estate ads to someone in New York, for example) or showing certain ads based on specific sites visited (such as showing cooking ads to someone who frequents cooking sites).</p>
                    <p>Third party vendors, including Google, use cookies to serve ads based on your prior visits to exams.sg or other websites. Google's use of advertising cookies enables it and its partners to serve ads to you based on your visit to exams.sg and/or other sites on the Internet. Users may opt out of personalized advertising by visiting <a href="https://www.google.com/settings/ads">https://www.google.com/settings/ads</a>. You can also opt out of a third-party vendor's use of cookies for personalized advertising by visiting <a href="http://www.aboutads.info">http://www.aboutads.info</a>. For more information on how Google uses data when you use Google’s partners’ sites or apps, visit <a href="https://www.google.com/policies/privacy/partners/">http://www.google.com/policies/privacy/partners/</a>.</p>
                    <p>We also may use DART cookies for ad serving through Google’s DoubleClick, which places a cookie on your computer when you are browsing the web and visit a site using DoubleClick advertising (including some Google AdSense advertisements). This cookie is used to serve ads specific to you and your interests (”interest based targeting”).</p>
                    <p>The ads served will be targeted based on your previous browsing history (For example, if you have been viewing sites about visiting Las Vegas, you may see Las Vegas hotel advertisements when viewing a non-related site, such as on a site about hockey). DART uses “non personally identifiable information”. It does NOT track personal information about you, such as your name, email address, physical address, telephone number, social security numbers, bank account numbers or credit card numbers.</p>
                    <p>You can opt-out of this ad serving on all sites using this advertising by visiting http://www.doubleclick.com/privacy/dart_adserving.aspx</p>
                    <p>You can choose to disable or selectively turn off our cookies or third-party cookies in your browser settings, or by managing preferences in programs such as Norton Internet Security. However, this can affect how you are able to interact with our site as well as other websites. This could include the inability to login to services or programs, such as logging into forums or accounts.</p>
                    <p>Deleting cookies does not mean you are permanently opted out of any advertising program. Unless you have settings that disallow cookies, the next time you visit a site running the advertisements, a new cookie will be added.</p>
                    <br />
                    <p>1.2.3 Third party applications</p>
                    <b>Web Analytics</b>
                    <p>exams.sg uses Google Analytics, a web analytics service provided by Google Inc. of 1600 Amphitheater Parkway, Mountain View, CA 94043, USA (Google). Google Analytics also uses cookies to track users' online behaviors, their length of stay, and their sources of reference. The results are transferred to a Google server in the United States of America and stored there. </p>
                    <p>exams.sg uses Google Analytics as a tool to analyze how users use the platform. This process is anonymous. </p>
                    <p>Users can prevent the use of new cookies by using appropriate browser settings (see above). You can also disable Google Analytics by installing a browser add-on provided by Google at: http://tools.google.com/dlpage/gaoptout?hl=en. </p>
                    <p>For more information about Google Inc. and Google Analytics, please visit http://www.google.com. Google’s privacy policy can be found on the following website: https://policies.google.com/privacy </p>
                    <p>The legal basis for this processing of personal data is Section 13 of the Personal Data Protection Act 2012 (PDPA).</p>
                    <b>Newsletter / Email notifications</b>
                    <p>exams.sg uses the services of Mailgun to send emails. This service is provided by Mailgun Technologies, 535 Mission St., 14th Floor, San Francisco, CA 94105, USA. </p>
                    <p>Mailgun is a service which organizes and analyzes the distribution of newsletters. In order to send personalized emails and newsletter to users, personal data like users’ names and email address will be stored on Mailgun servers in the USA. </p>
                    <p>exams.sg uses Mailgun to analyze newsletter and email campaigns. When users open an email sent by Mailgun, a file included in the email (called a web beacon) connects to Mailgun's servers in the United States. This allows exams.sg to determine if an email message has been opened and which links users click on. In addition, technical information is collected (e.g. time of retrieval, IP address, browser type, and operating system). This information cannot be assigned to a specific recipient. It is used exclusively for the statistical analysis of our newsletter campaigns. The results of these analyses can be used to better tailor future newsletters and emails to users’ interests. </p>
                    <p>If users do not want their usage of newsletters to be analyzed by Mailgun, users will have to unsubscribe from the newsletter. For this purpose, we provide a link in every newsletter we send. Users can also unsubscribe from the newsletter directly on the website in their settings. </p>
                    <p>Users may revoke their consent to newsletters at any time by unsubscribing from the newsletter or email campaigns. More information on how to unsubscribe from the newsletter and email campaigns are detailed in paragraph 2.1.1 of this privacy policy. The data processed before exams.sg receives a user’s request may still be legally processed. </p>
                    <p>The data provided when registering for a newsletter will be used to distribute mails until users cancel their subscription when said data will be deleted from our servers and those of Mailgun. Data we have stored for other purposes (e.g. email addresses for the members area) remains unaffected. </p>
                    <p>For details, see the Mailgun privacy policy at <a className="link" href="https://Mailgun.com/legal/terms/.Live-Chat">https://Mailgun.com/legal/terms/.Live-Chat</a>.</p>
                    <br />
                    <p>1.3 Content data</p>
                    <p>Content data is data that users upload or post to the platform. These are, for example, the documents that users upload, the questions, answers and comments they post, and the content they make accessible to other users or generally accessible. exams.sg will not pass these data on to third parties. However, the content is accessible to other users and can be downloaded and copied by them.</p>
                    <div className="PP-header">2. Usage of data</div>
                    <p>exams.sg uses the data to offer its services to their users via their platform and to ensure the functionality of the platform. The contact data provided by the users serves to contact the users and manage the user account at exams.sg.</p>
                    <p>2.1 Stock data</p>
                    <p>2.1.1 Usage of stock data</p>
                    <p>The username, educational institution and profile picture (if any) are visible to all other users. The e-mail address and the self-chosen password remain secret. </p>
                    <p>Users are informed about changes and updates on exams.sg through the email address provided upon registration.</p>
                    <p>Users can at any time unsubscribe from email notifications by sending an e-mail to <a className="link" href="mailto:contact@exams.sg">contact@exams.sg</a> or by logging into their exams.sg account and changing their email settings on exams.sg.</p>
                    <p>The legal basis for this processing of personal data is Section 13 of the Personal Data Protection Act 2012 (PDPA).</p>
                    <br />
                    <p>2.1.2 Search functions and active feeds</p>
                    <p>A part of users’ personal information is accessible for and searchable by other users. This information includes the username and specified educational institution </p>
                    <div className="PP-header">3.1 Usage data</div>
                    <p>exams.sg is always striving to improve the platform for its users. Therefore, it determines how the platform is used to identify usage patterns, peak usage times, and the popularity of certain offers and features. </p>
                    <p>The data thus obtained is used to implement technical adaptations and functional improvements for the users. The use of analytical tools and cookies is therefore crucial to provide users with an excellent service. The collected information can help improve the platform for the users.</p>
                    <div className="PP-header">3.2 Content data</div>
                    <p>3.2.1 Accessibility of content</p>
                    <p>Users alone have control over the content they want to upload and make accessible to other users. This includes all kinds of files, comments and personal data. exams.sg merely provides the technical requirements and is not responsible for the actions of its users. If a user decides to upload material or information from third parties, he or she is solely responsible. The extent to which information is shared is not influenced by exams.sg. Therefore, only users decide which content they make accessible.</p>
                    <div className="PP-header">4. Information about users’ rights</div>
                    <p>4.1 Right to file complaints with regulatory authorities</p>
                    <p>If there has been a breach of data protection legislation, the person affected may file a complaint with the competent regulatory authorities. Personal Data Protection Commission (PDPC) is the data protection officer of Singapore in which exams.sg is headquartered. </p>
                    <br />
                    <p>4.2 Right to data portability</p>
                    <p>You have the right to have data which we process based on your consent or in fulfillment of a contract automatically delivered to yourself or to a third party in a standard, machine-readable format. If you require the direct transfer of data to another responsible party, this will only be done to the extent technically feasible.</p>
                    <br />
                    <p>4.3 Information and deletion of accounts</p>
                    <p>As permitted by law, you have the right to be provided at any time with information free of charge about any of your personal data that is stored as well as its origin, the recipient and the purpose for which it has been processed. You also have the right to have this data corrected, blocked or deleted. You can contact us at any time using the address given in our legal notice if you have further questions on the topic of personal data. Please note that if your Personal Data has been provided to us by a third party, you should contact such party directly to make any queries, feedback, and access and correction requests to SPH on your behalf.</p>
                    <p>Thank you for reading our privacy policy! If you have any questions about our policy, you can always contact us at <a className="link" href="mailto:contact@exams.sg">contact@exams.sg</a>.</p>
                    <p>Last Updated: November 8 2019</p>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="CloseButton" onClick={handleClose}>
                        Sign up now!
                    </Button><br/>
                    <div className="col-12">
                        Can’t find what you’re looking for?<br/>
                        Drop us an email at <a className="link" href="mailto:hello@exams.sg">hello@exams.sg</a>, and we’ll be happy to assist!
                    </div>
                </Modal.Footer>
            </Modal>
        </a>
    )
}

export default Privacy_policy;
