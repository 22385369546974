import React, { Component } from "react";
import Navbar from "../common/navbar/Navbar";
import Footer from "../common/footer/Footer";
import HoverCard from "./HoverCard";
import InitiativesVolunteer from "./InitiativesVolunteer";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { animateScroll as scroll } from "react-scroll";
import "./initiatives.css";
import PageTitle from "../common/PageTitle/PageTitle";

class Initiatives extends Component {
  constructor(props) {
    super(props);
    this.state = { initiatives: [], props : props };
    scroll.scrollTo(0, { duration: 500 });
    props.check_logged_in();
  }

  componentWillMount() {
    this.getInitiatives();
  }

  componentDidMount() {
    document.title = "SGExams | Initiatives";
  }

  getInitiatives() {
    fetch("/api/initiatives").then((res) => {
      try {
        res.json().then((value) => {
          this.setState({ initiatives: value });
        });
      } catch (err) {
        throw err;
      }
    });
  }

  render() {
    const initiatives = { ALL: [] };
    for (var i = 0; i < this.state.initiatives.length; i++) {
      initiatives[this.state.initiatives[i].category] = [];
    }
    for (var i = 0; i < this.state.initiatives.length; i++) {
      initiatives["ALL"].push(
        <Col xl={4} md={6} xs={12} className="hover-card-spacing">
          <HoverCard
            image={this.state.initiatives[i].image}
            tagline={this.state.initiatives[i].tagline}
          >
            {this.state.initiatives[i].description}
          </HoverCard>
        </Col>
      );
      initiatives[this.state.initiatives[i].category].push(
        <Col lg={4} md={6} xs={12} className="hover-card-spacing">
          <HoverCard
            image={this.state.initiatives[i].image}
            tagline={this.state.initiatives[i].tagline}
          >
            {this.state.initiatives[i].description}
          </HoverCard>
        </Col>
      );
    }

    return (
      <div>
        <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
        <div className="initiatives-page-content">
          <PageTitle header = "INITIATIVES" text = {"Check out our initiatives and events that we have carried out and keep a look out on our social media pages for more upcoming opportunities! \n Hover over the event to find out more details."}></PageTitle>
          <Tabs justify defaultActiveKey="ALL" className="tab_links mt-3 mx-3 row">
            {Object.keys(initiatives).map((name) => (
              <Tab className="col-sm-12" eventKey={name} title={name}  >
                <Container className="hover-card-container" fluid>
                  <Row className="row hover-card-row">{initiatives[name]}</Row>
                </Container>
              </Tab>
            ))}
          </Tabs>
        </div>
        <InitiativesVolunteer/>
        <Footer />
      </div>
    );
  }
}

export default Initiatives;
