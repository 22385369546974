import React, { Component } from 'react';
import './HoverCard.css';
import Card from 'react-bootstrap/Card';

class HoverCard extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="hover-card">
        <div className="hover-content">
          <Card className="inner-card">
            <Card.Img variant="top" src={this.props.image} className="init-image"/>
            <Card.Body className="row py-1 align-items-center card-tagline">
              <Card.Text className="col card-tagline-text">
                {this.props.tagline}
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="inner-card-text">
            <Card.Body className="p-4">
              {this.props.children}
            </Card.Body>
          </Card>
        </div>
      </div> 
    );
  }
}
  
export default HoverCard;