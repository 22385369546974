import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import './login.css'
import Form from 'react-bootstrap/Form' 
import Button from 'react-bootstrap/Button';
import { Redirect , Link} from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = { props : props, logged_in: props.logged_in, email: "" , password: "" , remember: false, wrong_login: false, login_success: false};
        this.handleChange = this.handleChange.bind(this);
        this.login = this.login.bind(this);
        scroll.scrollTo(0, {duration: 500});
        props.check_logged_in();
    }

    componentDidMount(){
        document.title = "SGExams | Login";
    }

    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }

    login(event){
        fetch('/api/login', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)
        }).then(res => {
            try {
                res.json().then(value => {
                    if (value.success){
                        this.setState({wrong_login: false, login_success: true});
                    } else {
                        this.setState({wrong_login: true});
                    }
                    this.state.props.check_logged_in();
                })
            } catch (err){
                throw err;
            }
        });
        event.preventDefault();
    }

    render(){
        if (this.state.login_success || this.state.logged_in){
            return <Redirect to="/library" />;
        }
        let error_msg;
        if (this.state.wrong_login){
            error_msg = <div className="error">Invalid email address or password</div>;
        } else {
            error_msg = null;
        }
        return (
            <div className="login-page">
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <div className="page-container login-background">
                    <div className='login-form col-md-6'>
                        <h2 className='login-header'>Log In</h2>
                        <Form onSubmit={this.login} className="login-form-content">
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="input-label">Email</Form.Label>
                                <Form.Control name="email"  className="loginFormInput" type="email" placeholder="examisfun@example.com" onChange={this.handleChange} required/>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Link className="forgetPW" to="reset">Forgot your password?</Link>
                                <Form.Label  className="input-label">Password</Form.Label>
                                <Form.Control name="password" className="loginFormInput" type="password" placeholder="********" onChange={this.handleChange} required/>
                            </Form.Group>
                            {error_msg}
                            <div className="row">
                                <Form.Group controlId="formBasicCheckbox" className="col-sm-6">
                                    <Form.Check name="remember"  type="checkbox" label="Remember me" onChange={this.handleChange}/>
                                </Form.Group>
                                <Button className="login-submit col-sm-6" type="submit">Let's go!</Button>
                            </div>
                        </Form>
                        <hr/>
                        <div className="sign-up">
                            New to SGExams? &nbsp;
                            <Link className="link" to="/register">Sign up now!</Link>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Login;
