import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./IntroCardLeft.css"

function IntroCardLeft(props) {
    return (
        <div className = "speech-block-with-image-left">
            <Image className = "speech-image-left" src = {props.image}></Image>
            <div className = "speech-block-left">
                <div className = "speech-block-inner-left">
                    <Row className = "speech-text-title-left">
                        <h4 className = "speech-text-title-text-left"> 
                            {props.title.toUpperCase()}
                        </h4>
                    </Row>
                    {props.speech.split("\n").map(
                        (x) => 
                        <div className = "justify-content-left speech-text-row-left">
                            <p className = "speech-text-text-left">{x}  </p>
                        </div>
                    )}
                    <div className = "dummy-spacing"></div>
                    <div className="text-right speech-name-left">
                        <h5 className = "speech-text-name-left">{props.name}  </h5>
                    </div>
                </div>

            </div>
        </div>
    )
}


export default IntroCardLeft;