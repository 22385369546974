import React, { Component } from 'react';
import './volunteer_info.css';
import volunteer_info_pic from './img/2.png';
import checkmark from './img/tick.png'
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

class Volunteer_Info extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Container fluid>
                <Row className = "sgexams-row">
                    <Col md={6} className="volunteer_info_left disappear-if-screen-small">
                        <Image className="volunteer_info_pic disappear-if-screen-small" src={volunteer_info_pic}/>
                    </Col>

                    <Col md={6} className="volunteer_info_right">
                        <div className="volunteer_info_header">
                            <p className="volunteer_info_header_text">Come together with other like-minded individuals in serving and making the student community a better place for students in Singapore!</p>
                        </div>
                        <div className="volunteer_info_checkmark_info">
                            <Row className = "checkmark-row justify-content-center">
                                <Col xs="auto">
                                    <Image src={checkmark} width="20" height="20" fluid />
                                </Col>

                                <Col>
                                    <p className = "checkmark-text">No age requirement</p>
                                </Col>
                            </Row>
                            <Row className = "checkmark-row">
                                <Col xs="auto">
                                    <Image src={checkmark} width="20" height="20" fluid />
                                </Col>

                                <Col >
                                    <p className = "checkmark-text">New opportunities and experience</p>
                                </Col>
                            </Row>
                            <Row className = "checkmark-row">
                                <Col xs="auto">
                                    <Image src={checkmark} width="20" height="20" fluid />
                                </Col>

                                <Col >
                                    <p className = "checkmark-text">Build portfolio</p>
                                </Col>
                            </Row>
                            <Row className = "checkmark-row">
                                <Col xs="auto">
                                    <Image src={checkmark} width="20" height="20" fluid />
                                </Col>

                                <Col >
                                    <p className = "checkmark-text">Make new connections</p>
                                </Col>
                            </Row>
                        </div>
                        <br></br>
                    </Col>
                </Row>
            </Container>
        );
    }

}

export default Volunteer_Info;