import React from 'react';
import './partners_reviews.css';
import ReviewsImage from './img/17.svg';
import { Container, Row, Col, Image } from 'react-bootstrap';

const PartnersReviewsCarousel = () => {
    return (
        <div className="reviews-carousel-container">
            <div id="reviewsCarousel" className="carousel slide reviews-carousel-slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <div className="carousel-content">
                            <p className="reviews-carousel-text">"SGExams remains as our favorite youth community because the community is inclusive, stemming from having members of the team from every background of education like secondary, ITE, Poly, JC, and University. The team is attentive to the needs of the diverse student population and also active in work with other social enterprises and partners who can augment areas that they have gaps in."</p>
                            <p>- Praxium</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <div className="carousel-content">
                            <p className="reviews-carousel-text">"SGExams is a Youth Corps Affiliate and has been a valuable partner of Youth Corps Singapore. We have collaborated on several community service projects, from Beach Cleanups to Appreciation of Front-liners during the Circuit Breaker period in year 2020. The Youths at SGExams have demonstrated great passion in rallying fellow youths to care and take action for the community and the environment."</p>
                            <p>- Youth Corps Singapore</p>
                        </div>
                    </div>
                </div>

                <a className="reviews-carousel-left carousel-control-prev" href="#reviewsCarousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="reviews-carousel-right carousel-control-next" href="#reviewsCarousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
        </div>
    )
}

const PartnersReviews = () => {
    return (
        <div className="partners-reviews justify-content-between sgexams-row">
            <div className="reviews-text">
                <div className="partners-reviews-header">Reviews</div>
                <p>What other organisations have to say about us:</p>
            </div>
            <Row className = "image-row">
                <Col className = "caruosel" lg={6}>
                    <PartnersReviewsCarousel/>
                </Col>
                <Col lg={6}>
                    <Image className="reviews-image mr-5 disappear-if-screen-small" src={ReviewsImage} alt="Reviews Image" />
                </Col>
            </Row>
        </div>
    )
}

export default PartnersReviews;