import React, { Component } from 'react';
import partner_image_1 from './images/partner_image_1.png';
import partner_image_2 from './images/partner_image_2.png';
import partner_image_3 from './images/partner_image_3.png';
import partner_image_4 from './images/partner_image_4.png';
import './partners.css';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';

class partners extends Component {
  render() {
    const images = [
        partner_image_1,
        partner_image_2,
        partner_image_3,
        partner_image_4
    ];
    const logos = [];
    for (var i=0; i < images.length; i++) {
        logos.push(
            <div key={i} className="partners-image col">
                <img src={ images[i] } alt="Partner Logo" height="100%"></img>
            </div>
        );
    }
    return (
        <div className="partners">
            <div className="partners-header">
                Our Partners
            </div>
            <div className="">
                <div className="row partners-images">
                    { logos }
                </div>
            </div>
            <Link to="partners">
                <Button className="partners-button" variant="link">
                    Find out more
                </Button>
            </Link>
        </div>
    
    );
  }
}

export default partners;
