import React, { Component, useState } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { animateScroll as scroll } from "react-scroll";
import { Redirect } from "react-router-dom";
import PageTitle from "../common/PageTitle/PageTitle";
import { Button, Table, Row, Col, ButtonGroup } from 'react-bootstrap';
import './curation.css';
import RejectModal from './reject_modal';

class NotesCuration extends Component {
    constructor(props) {
        super(props);
        scroll.scrollTo(0, { duration: 500 });
        this.buttonData = [
            {
                name: "APPROVED",
                value: "approved",
            },
            {
                name: "PENDING",
                value: "pending",
            },
            {
                name: "REJECTED",
                value: "rejected",
            }
        ];
        this.state = { 
            logged_in: true,
            activeButton: "APPROVED",
            listings: [],
            props : props
        };
        props.check_logged_in();
        this.handleClick = this.handleClick.bind(this);
        this.handleApprove = this.handleApprove.bind(this);
        this.get_listings = this.get_listings.bind(this);
    }

    componentWillMount() {
        this.get_listings();
    }

    componentDidMount() {
        document.title = 'SGExams | Notes Curation';
    }
    
    get_listings() {
        fetch("/api/curation").then((res) => {
            try {
                res.json().then((value) => {
                    if (value.success){
                        this.setState({ listings: value.listings });
                    } else {
                        this.setState({ logged_in : false });
                    }
                });
            } catch (err) {
                throw err;
            }
        });
    }

    handleApprove(event) {
        fetch('/api/curation', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({action: "accept", listing_id: event.target.value })
        }).then(res => {
            this.get_listings();
        });
        event.preventDefault();
    }

    handleClick(event){
        this.setState({activeButton: event.target.name});
    }

    render() {
        if (!this.state.logged_in) {
            return <Redirect to="/login" />;
        }
        const listings = { 
            APPROVED: [],
            PENDING: [],
            REJECTED: []
        };
        for (const listing of this.state.listings) {
            const files = [];
            for (const file of listing["files"]) {
                files.push(
                    <a href={file.file_link}><div width="100%">{file.file_name}</div></a>
                )
            }
            if (listing["vetted"] == true && listing["rejected"] == false) {
                listings["APPROVED"].push(
                    <tr className="curation-table">
                        <td>Approved</td>
                        <td>{listing["id"]}</td>
                        <td>{listing["subject"]}</td>
                        <td>{listing["author"]}</td>
                        <td>{listing["title"]}</td>
                        <td>{listing["last_updated"]}</td>
                        <td className="curation-file-links">{files}</td>
                    </tr>
                )
            }
            else if (listing["vetted"] == true && listing["rejected"] == true) {
                listings["REJECTED"].push(
                    <tr className="curation-table">
                        <td>Rejected</td>
                        <td>{listing["id"]}</td>
                        <td>{listing["subject"]}</td>
                        <td>{listing["author"]}</td>
                        <td>{listing["title"]}</td>
                        <td>{listing["last_updated"]}</td>
                        <td className="curation-file-links">{files}</td>
                    </tr>
                )
            }
            else if (listing["vetted"] == false) {
                listings["PENDING"].push(
                    <tr className="curation-table">
                        <td>
                            <div className="text-center" aria-label="Accept/Reject">
                                <ButtonGroup>
                                    <Button variant="success" size="xs" value={ listing.id } onClick={this.handleApprove}>
                                        APPROVE
                                    </Button>
                                    <RejectModal listing_id = { listing.id } props = {this.state.props} reload = {this.get_listings}/>
                                </ButtonGroup> 
                                </div>
                        </td>
                        <td>{listing["id"]}</td>
                        <td>{listing["subject"]}</td>
                        <td>{listing["author"]}</td>
                        <td>{listing["title"]}</td>
                        <td>{listing["last_updated"]}</td>
                        <td className="curation-file-links">{files}</td>
                    </tr>
                )
            }
        }

        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <div className="curation-page">
                    <PageTitle header = "NOTES CURATION" text={"Notes Curation Platform"}></PageTitle>
                    <ButtonGroup className="text-center">
                        {
                            this.buttonData.map(item => {
                                const className = this.state.activeButton === item.name ? "active" : "";
                                return (
                                    <Button className={`${className}` + " curation-filter-button"} name={item.name} value={item.value} onClick={this.handleClick}>{item.name}</Button>
                                )
                            })
                        }
                    </ButtonGroup>
                    <div className="curation-list mt-4">
                        <Table bordered striped className="curation-table">
                            <thead>
                                <tr>
                                    <th>
                                        ACTIONS
                                    </th>
                                    <th>
                                        ID
                                    </th>
                                    <th>
                                        SUBJECT
                                    </th>
                                    <th>
                                        AUTHOR
                                    </th>
                                    <th>
                                        TITLE
                                    </th>
                                    <th>
                                        LAST UPDATED
                                    </th>
                                    <th>
                                        FILES
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { listings[this.state.activeButton] }
                            </tbody>
                        </Table>
                        
                    </div>

                </div>
                <Footer/>
            </div>
           
        )
    }
}

export default NotesCuration;