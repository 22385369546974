import React, { Component } from 'react';

import './pagination.css';

export class Pagination extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const pageNumbers = [];
        for (let i=1; i <= Math.ceil(this.props.totalItems / 4); i++) {
            if (i === this.props.currentPage) {
                pageNumbers.push(
                    <div key={i} className="notes-comments-pagination-dot active-page-dot" 
                    onClick={() => this.props.changeCurrentPage(i)}></div>
                );
            } else {
                pageNumbers.push(
                    <div key={i} className="notes-comments-pagination-dot" 
                    onClick={() => this.props.changeCurrentPage(i)}></div>
                );
            }
        }

        return (
            <div className="notes-comments-pagination-container">
                {pageNumbers}
            </div>
        )
    }
}

export default Pagination;
