import React, { Component } from 'react';
import { Link} from "react-router-dom";

import './subjectCard.css';

export class SubjectCard extends Component {
    render() {
        return (
            <Link className="custom-card-link col-12 col-sm-6 col-md-4 col-lg-3" to={"/library/notes/subject/" + this.props.link}>
                <div className="card custom-subject-card">
                    <img className="card-img-top custom-subject-image" height="200px" src={this.props.img} alt={this.props.subject}/>
                    <h5 className="custom-card-title">{this.props.subject}</h5>
                </div>
            </Link>
        )
    }
}

export default SubjectCard;
