import React, { Component } from 'react';
import registered_image from './images/registered_image.png';
import './registered.css';
import Button from 'react-bootstrap/Button';
import { HashLink } from 'react-router-hash-link';

class registered extends Component {
  render() {
    return (
        <div className="registered">
            <div className="registered-header">
                We are now a registered society!
            </div>
            <div className="registered-image">
                <img src={ registered_image } alt="Registered Image" height="65px" />
            </div>
            <div className="registered-description">
                This means that we can carry out bigger, better projects! Not only that, we can recognise hours that are put in by our volunteers, and much more! In other words, there are more opportunities for you to beef up your portfolio for school, as well as internship applications!
            </div>
            {
            <HashLink to="contribute#volunteer">
                <Button className="registered-button" variant="link">
                    Volunteer with us
                </Button>
            </HashLink>
            }
        </div>
    );
  }
}

export default registered;
