import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Navbar from "../common/navbar/Navbar";
import Footer from "../common/footer/Footer";
import NotesCommentsRow from "./subComponents/notesCommentsRow";
import Pagination from "./subComponents/pagination";

import Banner from "./img/1.png";
import DefaultProfilePicture from "./img/2.png";
import EndingImage from "./img/3.png";
import BackgroundImage from "./img/4.png";
import "./profile.css";
import { animateScroll as scroll } from "react-scroll";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFetchingData: true,

      toggleNotesClassName: "btn btn-notes-comments active",
      toggleCommentsClassName: "btn btn-notes-comments",

      allItems: [],
      currentPage: 1,
      username: "",
      profile_picture: "",
      school: "",
      listings: [],
      comments: [],
      allItems: [],
      joined: "",
      props: props,
    };
    props.check_logged_in();

    this.fetchUserData = this.fetchUserData.bind(this);
    this.toggleData = this.toggleData.bind(this);
    this.changeCurrentPage = this.changeCurrentPage.bind(this);
    scroll.scrollTo(0, { duration: 500 });
  }

  componentWillMount() {
    if (!this.state.props.logged_in) {
      return <Redirect to="/login" />;
    } else {
      this.fetchUserData();
    }
  }

  fetchUserData() {
    fetch("/api/profile").then((res) => {
      try {
        res.json().then((value) => {
          // Load user info to the state if successful.
          if (value.success) {
            this.setState({
              username: value.username,
              profile_picture: value.profile_picture,
              school: value.school,
              listings: value.listings,
              comments: value.comments,
              allItems: value.listings,
              joined: value.created_at.split("T")[0],
            });
          }

          // Finish loading the page.
          this.setState({
            isFetchingData: false,
          });
          this.props.check_logged_in();
        });
      } catch (err) {
        throw err;
      }
    });
  }

  // Toggle between viewing notes and viewing comments
  toggleData(event) {
    const request = event.target.innerHTML;

    if (request === "COMMENTS") {
      this.setState({
        allItems: this.state.comments,
        currentPage: 1,
        toggleNotesClassName: "btn btn-notes-comments",
        toggleCommentsClassName: "btn btn-notes-comments active",
      });
    } else {
      this.setState({
        allItems: this.state.listings,
        currentPage: 1,
        toggleNotesClassName: "btn btn-notes-comments active",
        toggleCommentsClassName: "btn btn-notes-comments",
      });
    }
  }

  // Handles Pagination
  changeCurrentPage(page) {
    this.setState({ currentPage: page });
  }

  render() {
    // Page is still loading.
    if (!this.props.logged_in) {
      return <Redirect to="/login" />;
    } else {
      const indexOfLastItem = this.state.currentPage * 4;
      const indexOfFirstItem = indexOfLastItem - 4;
      const currentItems = this.state.allItems.slice(
        indexOfFirstItem,
        indexOfLastItem
      );

      return (
        <div className="profile-page">
          <Navbar
            check_logged_in={this.state.props.check_logged_in}
            logged_in={this.state.props.logged_in}
          />

          <img
            className="background-image"
            id="background-image-1"
            src={BackgroundImage}
            alt="background"
          />
          <img
            className="background-image"
            id="background-image-2"
            src={BackgroundImage}
            alt="background"
          />

          <img className="profile-banner-image" src={Banner} alt="Banner" />

          <div className="profile-header-information">
            <img src={DefaultProfilePicture} alt="profile picture" />

            <div className="profile-header-title">
              <h4>{this.state.username}</h4>

              <div className="profile-header-subtitle">
                <p className="school-name">{this.state.school}</p>

                <a href="/profile/edit" className="btn edit-button">
                  EDIT PROFILE
                </a>
              </div>

              <p className="joined-date">JOINED {this.state.joined}</p>
            </div>
          </div>

          <div className="notes-comments-counter-container">
            <div className="notes">
              <h5>NOTES</h5>
              <h4>{this.state.listings.length}</h4>
            </div>
            <div className="comments">
              <h5>COMMENTS</h5>
              <h4>{this.state.comments.length}</h4>
            </div>
          </div>

          <ul className="list-group notes-comments-container">
            <div
              className="btn-group btn-group-toggle btn-notes-comments-group"
              data-toggle="buttons"
            >
              <label
                onClick={this.toggleData}
                className={this.state.toggleNotesClassName}
              >
                NOTES
              </label>
              <label
                onClick={this.toggleData}
                className={this.state.toggleCommentsClassName}
              >
                COMMENTS
              </label>
            </div>

            <NotesCommentsRow currentItems={currentItems} />

            <Pagination
              totalItems={this.state.allItems.length}
              currentPage={this.state.currentPage}
              changeCurrentPage={this.changeCurrentPage}
            />
          </ul>

          <img className="ending-image" src={EndingImage} alt="ending image" />

          <Footer />
        </div>
      );
    }
  }
}

export default Profile;
