import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import "./PersonDisplay.css"

function PersonDisplay(props) {
    return (
        <div>
            <div className="justify-content-md-center"> 
                <Image className = "person-display-picture" src = {props.image} width="100%"/>
            </div>
            <div className="justify-content-md-center">
                <p className = "person-display-name" >{props.name.toUpperCase()}</p> 
                <p className = "person-display-role" >{props.role.toUpperCase()}</p> 
            </div>

        </div>
    )
}

export default PersonDisplay;