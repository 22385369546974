import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import './components/common/sgexams.css';
import Home from './components/home/home';
import AboutUsTeam from './components/AboutUsTeam/about';
import Contact_us from './components/contact_us/contact_us';
import Contribute from './components/contribute/contribute';
import Partners from './components/partners/partners';
import Login from './components/Login/login';
import Register from './components/register/register';
import Reset from './components/reset/reset';
import Initiatives from './components/Initiatives/initiatives'
import Library from './components/Library/library';
import LibraryNotes from './components/libraryNotes/libraryNotes';
import LibrarySubjectNotes from './components/libraryNotes/librarySubjectsNotes/librarySubjectsNotes';
import LibrarySearchNotes from './components/libraryNotes/librarySearchNotes/librarySearchNotes';
import Profile from './components/profile/profile';
import ProfileEdit from './components/profile/profileEdit';
import UploadNotes from "./components/UploadNotes/uploadNotes";
import NotFound from "./components/NotFound/notFound";
import SubmitNotesResult from "./components/submitNotesResult/submitNotesResult";
import Curation from "./components/curation/curation";
import { createBrowserHistory } from 'history';
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA);

var history = createBrowserHistory();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {subject : "ONLevel", logged_in : true, username: "username"};
    this.changeSubject = this.changeSubject.bind(this);
    this.check_logged_in = this.check_logged_in.bind(this);
  }

  changeSubject(subject){
    this.setState({subject : subject});
  }

  componentWillMount(){
    this.check_logged_in();
  }

  check_logged_in(){
      fetch('/api/authenticate').then(res => {
          try {
              res.json().then(value => {
                  if (value.success !== this.state.logged_in || value.username !== this.state.username){
                    this.setState({logged_in : value.success, username: value.username})
                  }
              })
          } catch (err){
              throw err;
          }
      })
  }

  render() {
    history.listen((location) => {
      ReactGA.pageview(location.pathname, location.search);
    });
    return (
      <div>
       <Router history={history}>
          <Switch>
            <Route path="/" component={ () => <Home check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/about_us" component={ () => <AboutUsTeam check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/contact_us" component={ () => <Contact_us check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/login" component={ () => <Login check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/register" component={ () => <Register check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/reset" component={ () => <Reset check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/contribute" component={ () => <Contribute check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/partners" component={ () => <Partners check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/initiatives" component={ () => <Initiatives check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/library" component={ () => <Library check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/profile" component={ () => <Profile check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/profile/edit" component={ () => <ProfileEdit check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/curation" component={ () => <Curation check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/library/notes" component={ (parent) => <LibraryNotes parent = {parent} check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in} subject = {this.state.subject} changeSubject = {this.changeSubject}/>} exact/>
            <Route path="/privacy_policy" component={ () => <Register check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in} privacy_policy = {true} /> } exact/>
            
            
            <Route path="/library/notes/ALevel" component={ () => {
              this.changeSubject("ALevel");
              return <Redirect to="/library/notes"/>
            }} exact/>
            <Route path="/library/notes/ONLevel" component={ () => {
              this.changeSubject("ONLevel");
              return <Redirect to="/library/notes"/>
            }} exact/>
            <Route path="/library/notes/IB" component={ () => {
              this.changeSubject("IB");
              return <Redirect to="/library/notes"/>
            }} exact/>
            <Route path="/library/notes/subject/:code" component={ () => <LibrarySubjectNotes check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/>} exact/>
            <Route path="/library/notes/search/:query" component={ () => <LibrarySearchNotes check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/>} exact/>
            <Route path="/notfound" component={ () => <NotFound check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/upload" component={ () => <UploadNotes check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in} username = {this.state.username}/> } exact/>
            <Route path="/notesSubmitted" component={ () => <SubmitNotesResult check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> } exact/>
            <Route path="/articles" component={() => {
              window.location.href = '//medium.com/sgexams'; 
              return null;
            }} />
            <Route component={ () => <NotFound check_logged_in = {this.check_logged_in} logged_in = {this.state.logged_in}/> }/>
          </Switch>
        </Router>
      </div>
    );
  }
}
 
ReactDOM.render(
  <App />,
  document.getElementById('root')
);


// If you want to start measuring performance in your testing_page, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
