import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import Carousel from './carousel';
import Registered from './registered';
import Values from './values';
import Volunteer from './volunteer';
import Partners from './partners';
import { animateScroll as scroll } from 'react-scroll'

class home extends Component {
  constructor(props) {
    scroll.scrollTo(0, {duration: 500});
    super(props);
    this.state = {props : props};
    props.check_logged_in();
  }

  componentDidMount(){
    document.title = "SGExams";
  }

  render(){
    return (
      <div id="Home">
        <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
        <div>
          <Carousel />
            <Registered />
            <Values />
            <Volunteer />
            <Partners />
        </div>
        <Footer />
      </div>
    );
  }
}

export default home;
