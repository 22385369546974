import React, { Component } from 'react';
import './StreamCard.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'

class StreamCard extends Component {
    constructor(props) {
      super(props);
    }
  
    render() {
      return (
          <div className="d-grid gap-2">
            <Button variant="light" className="stream-button btn-sgexams" href={this.props.link} size="lg" fluid>
                <p className="stream-button-text">{this.props.title}</p>
                <Image src={this.props.image} className="stream-button-image disappear-if-screen-small" fluid/>
            </Button>
          </div>
      );
    }
  }
    
  export default StreamCard;