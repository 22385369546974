import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { animateScroll as scroll } from 'react-scroll'
import PartnersReviews from './partners_reviews';
import PartnersCollaborate from './partners_collaborate';
import PageTitle from '../common/PageTitle/PageTitle';
import { Col, Container, Image, Row } from 'react-bootstrap';
import "./partners.css"

import MOE from './img/1.png';
import NYC from './img/2.png';
import YCS from './img/3.png';
import YNTUC from './img/4.png';
import PRAXIUM from './img/5.png';
import SEALED from './img/6.png';
import SKILLSGOOD from './img/7.png';
import ACCESET from './img/8.png';
import ALITTLECHANGE from './img/9.png';
import NEESOONEAST from './img/10.png';
import TACOSG from './img/11.png';
import SAMH from './img/12.png';
import INVEST from './img/13.png';
import MONEYBEES from './img/14.png';
import SEAKERS from './img/15.png';
import WOAHUNI from './img/16.png';

class Partners extends Component {
    constructor(props) {
        super(props);
        scroll.scrollTo(0, {duration: 500});
        this.state = {props : props};
        props.check_logged_in();
    }

    componentDidMount(){
        document.title = "SGExams | Partners";
    }

    render() {
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <PageTitle header = "Our Partners" text = {"SGExams continues to thrive with the continued support of our partners.\n Check out some of them below:"}></PageTitle>
                <div className="container-fluid">
                    <Row className="justify-content-center sgexams-row">
                        <Col xs="6" lg="3"md = "4" className = "my-auto">
                            <Image className="logo" src={MOE} alt="MOE" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto" >
                            <Image className="logo" src={NYC} alt="NYC" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={YCS} alt="YCS" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={YNTUC} alt="YNTUC" fluid/>
                        </Col>
                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={PRAXIUM} alt="PRAXIUM" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={SEALED} alt="SEALED"  fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={SKILLSGOOD} alt="SKILLSGOOD" fluid/>
                        </Col>

                        <Col xs="6" lg="3"md = "4" className = "my-auto">
                            <Image className="logo" src={ACCESET} alt="ACCESET" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={ALITTLECHANGE} alt="ALITTLECHANGE" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={NEESOONEAST} alt="NEESOONEAST" fluid/>
                        </Col>
                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={TACOSG} alt="TACOSG" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={SAMH} alt="SAMH"  fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={INVEST} alt="INVEST" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={MONEYBEES} alt="MONEYBEES" fluid/>
                        </Col>

                        <Col xs="6" lg="3" md = "4" className = "my-auto">
                            <Image className="logo" src={SEAKERS} alt="SEAKERS" fluid/>
                        </Col>

                        <Col xs="6" lg="3"md = "4" className = "my-auto">
                            <Image className="logo" src={WOAHUNI} alt="WOAHUNI" fluid/>
                        </Col>
                    </Row>
                </div>
                <br></br>
                <PartnersReviews />
                <PartnersCollaborate />
                <Footer />
            </div>
        );
    }

}

export default Partners;
