import React, { Component } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import LIBRARYFOOTERBG from './img/11.png';
import { Link } from 'react-router-dom';

import './libraryFooter.css';

export class LibraryFooter extends Component {

    render() {
        return (
            <div className="libraryFooterContainer">
                <img className="LibraryFooterBG" src={LIBRARYFOOTERBG} alt="LibraryFooterBG" style={{ width: '100%', height: '100%' }}></img>
                <div className="libraryFooterCentered">
                    <div className="libraryFooterHeader">Help out a fellow student</div>
                    <div className="libraryFooterContent">
                        Feeling generous? Have some game-changing notes you want to share? Share them with the community and help your fellow students.
                    </div>
                    <div>
                        <Button as={Link} to="/upload" className="libraryFooterDonateBtn" variant="dark">Donate your notes</Button>

                    </div>

                </div>


            </div>
        )
    }
}


export default LibraryFooter;