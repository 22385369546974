import React, { Component } from 'react';
import './contribute_studynow.css';
import study_now_pic from './img/5.png';
import { Image, Row} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Contribute_StudyNow extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div id="donations" className="d-flex study-now align-items-center justify-content-between sgexams-row">
                <Image src={study_now_pic} className="study-now-image disappear-if-screen-small"/>
                <Row className = "justify-content-center">
                    <div className="text-right contribute-study-text">
                        <h4 className="study-now-header">Study Notes</h4>
                        <p>Done with exams? Don’t burn your notes; contribute them to the community!</p>
                        <Link className="btn donate_notes_btn text-center" to="library" role="button">Donate Notes</Link>
                    </div>
                </Row>
                
            </div>
        )
    }

}

export default Contribute_StudyNow;