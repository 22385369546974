import React from 'react';
import './contribute_join_team.css';
import {Row, Col, Card, Accordion } from 'react-bootstrap';

import JoinTeamPicture from './img/3.png';
import Pencil from './img/pencil.png';
import { Link } from 'react-router-dom';

const ContributeGridCard = ({cardName}) => {
    return (
        <Col md = {4} className="d-flex align-items-center join-team-card">
            <img src={Pencil} className="custom-pencil mr-2"/>
            <p className="join-team-card-text checkmark-text">{cardName}</p>
        </Col>
    )
}

const ContributeJoinTeam = () => {
    return (
        <div className="contribute-join-team d-flex justify-content-between align-items-center sgexams-row">
            <div className="d-flex flex-column align-items-center flex-fill">
                <h4 className="join-team-header">Join Our Team</h4>
                <div className="team-grid">
                    <Row  className = "join-team-row">
                        <ContributeGridCard cardName="Strategy Group" />
                        <ContributeGridCard cardName="Operations" />
                        <ContributeGridCard cardName="Student Welfare" />
                    </Row>
                    <Row className = "join-team-row">
                        <ContributeGridCard cardName="Platforms" />
                        <ContributeGridCard cardName="Academics" />
                        <ContributeGridCard cardName="Marketing & Communications" />
                    </Row>
                    <Row className = "join-team-row">
                        <ContributeGridCard cardName="Education & Career Guidance" />
                        <ContributeGridCard cardName="Holistic Development" />
                        <ContributeGridCard cardName="Human Resource" />
                    </Row>
                </div>
                <Link className="btn btn-join-team text-center" to="about_us" role="button">Find Out More!</Link>
            </div>
            <img src={JoinTeamPicture} className="join-team-picture disappear-if-screen-small"/>
        </div>
    )
}

export default ContributeJoinTeam;