import React, { Component } from 'react';
import student_oriented_image from './images/student_oriented_image.png';
import committee_oriented_image from './images/committee_oriented_image.png';
import stakeholder_oriented_image from './images/stakeholder_oriented_image.png';
import './values.css';

class values extends Component {
  render() {
    const content = [
        {
            image: student_oriented_image, 
            header: "Student-oriented", 
            description: "The local student community is our focus and mission, and our group a platform for purposeful and rigorous learning."
        },
         {
            image: committee_oriented_image, 
            header: "Committee-oriented", 
            description: "It is our ambition to represent and safeguard the student voice as credible and valuable for inclusion in the greater community."
        }, 
        {
            image: stakeholder_oriented_image, 
            header: "Stakeholder-oriented", 
            description: "SGExams pledges to serve as a positive influence in the education sector for all stakeholders."
        }
    ];
    const result = [];
    for (var i=0; i < content.length; i++) {
        result.push(
            <div key = {content[i].header} className="col-md-4 col-12">
                <div className="values-image">
                    <img src={ content[i].image } alt={ content[i].header } height="150px"></img>
                </div>
                <div className="values-header">
                    { content[i].header }
                </div>
                <div className="values-description">
                    { content[i].description }
                </div>
            </div>
        );
    }
    return (
        <div className="values container-fluid">
            <div className="row">
                { result } 
            </div>
        </div>
    )
  }
}

export default values;
