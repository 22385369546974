import React, { Component } from 'react';
import Navbar from '../../common/navbar/Navbar';
import Footer from '../../common/footer/Footer';
import './librarySubjectNotes.css';
import LibrarySearch from '../librarySearch';
import LibraryFooter from '../libraryFooter';
import { Col } from 'react-bootstrap';
import NotesCard from '../subComponents/notesCard';
import { animateScroll as scroll } from "react-scroll";
import { withRouter } from "react-router";

export class LibrarySubjectNotes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            link: "",
            subjectCode: [],
            subjectNotes: [],
            loading: true,
            props : props
        }
        props.check_logged_in();
        this.state.link = this.props.match.params.code;
        this.state.subjectCode.push(this.props.match.params.code);
        this.reload = this.reload.bind(this);
        scroll.scrollTo(0, {duration: 500});
    }

    componentDidMount() {
        var title = 'SGExams | Library Notes | ' + this.state.link;
        document.title = title;
    }

    componentWillMount() {
        this.retrieve_subject_notes();
    }

    retrieve_subject_notes() {
        var tempSubjectNotes = []
        for (var i = 0; i < this.state.subjectCode.length; i++) {
            var requestURL = '/api/listing/subject/' + this.state.subjectCode[i];
            fetch(requestURL).then(res => {
                try {
                    res.json().then(value => {
                        if (value[0] != "subject not found") {
                            tempSubjectNotes = value;
                        }
                        this.setState({ subjectNotes: tempSubjectNotes, loading : false });
                    })
                } catch (err) {
                    throw err;
                }
            })
        }
    }

    reload(){
        this.retrieve_subject_notes();
    }

    // if response returned notes of subjects, then render the notes row. Otherwise, will render empty notes response.
    checkIfSubjectNotesResponseIsEmpty() {
        if (this.state.loading) return this.renderLoadingSubjectNotesResponse();
        var subjectsNotesRender = this.state.subjectNotes.length != 0 ? this.renderSubjectNotesResponse() : this.renderEmptySubjectNotesResponse();
        return subjectsNotesRender;
    }

    // renders empty notes response
    renderEmptySubjectNotesResponse() {
        return <div>
            <Col lg={12}>
            <h5 style={{ marginTop: '2%', marginLeft: '2%' }}>No relevant notes found.</h5>
            </Col>
        </div>
    }

    // renders empty notes response
    renderLoadingSubjectNotesResponse() {
        return <div>
            <Col lg={12}>
                <h5 style={{ marginTop: '2%', marginLeft: '2%' }}>Loading...</h5>
            </Col>
        </div>
    }

    // renders subject notes
    renderSubjectNotesResponse() {
        var subjectsNotesRender = this.state.subjectNotes.map((data, id) => {
                let newDateFormat = new Date(data.created_at);
                let created_at_year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDateFormat);
                let created_at_month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(newDateFormat);
                let created_at_day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDateFormat);
                let created_at_date = created_at_day + '/' + created_at_month + '/' + created_at_year;
                return <div key={id}>
                    <Col lg={12}>
                        <NotesCard data={data} created_at_date = {created_at_date} reload={this.reload}/>
                    </Col>
                </div>
            })
        return subjectsNotesRender;
    }

    render() {
        // call method to render notes if any
        var subjectsNotesResponse = this.checkIfSubjectNotesResponseIsEmpty();
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <LibrarySearch />
                <div className="subject-results-content">
                    <div className="subject-results-header">
                        Results for subject code: {this.state.link}
                    </div>
                    {subjectsNotesResponse}
                </div>
                <LibraryFooter />
                <Footer />
            </div>
        )
    }
}

export default withRouter(LibrarySubjectNotes);