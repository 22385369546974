import React, { Component } from 'react';
import logo from './img/white.png';
import telegramLogo from './img/telegram.png';
import discordLogo from './img/discord.png';
import redditLogo from './img/reddit.png';
import linkedinLogo from './img/linkedin.png'
import instagramLogo from './img/instagram.png'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Footer.css';
import { Link } from 'react-router-dom';

class footer_component extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Navbar className="footer navbar-dark-footer container-fluid" variant="dark">
                <div className="mainFooter row">
                    <div className="sgexamsLogo col col-lg-2">
                        <Nav.Link as={Link} to="/">
                            <img src={logo} width="120" height="90" className="d-inline-block align-middle" alt="logo" />
                        </Nav.Link>
                    </div>
                    <Navbar.Collapse className="footer-nav-links col col-lg-7" id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                            <Nav.Link as={Link} to="/about_us">About Us</Nav.Link>
                            <Nav.Link as={Link} to="/initiatives">Initiatives</Nav.Link>
                            <Nav.Link as={Link} to="/contribute">Contribute</Nav.Link>
                            <Nav.Link as={Link} to="/library">Library</Nav.Link>
                            <Nav.Link as={Link} to="/contact_us">Contact Us</Nav.Link>
                            <Nav.Link as={Link} to="/login">Log In</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                    <div className="socialMedia col col-lg-3">
                        <div className="content">
                            <p className="communityText">Join our community</p>
                            <div className="medialogos">
                                <a href="https://t.me/SGExamsPortal"><img src={telegramLogo} height="36" className="d-inline-block align-middle" alt="telegram logo" /></a>
                                <a href="https://discord.gg/sgexams"><img src={discordLogo} className="discordLogo" height="36" className="d-inline-block align-middle" alt="discord logo" /></a>
                                <a href="https://www.reddit.com/r/SGExams/"><img src={redditLogo} height="36" className="d-inline-block align-middle" alt="reddit logo" /></a>
                                <a href="https://sg.linkedin.com/company/sgexams"><img src={linkedinLogo} height="36" className="d-inline-block align-middle" alt="linkedin logo" /></a>
                                <a href="https://www.instagram.com/sgexams/"><img src={instagramLogo} height="36" className="d-inline-block align-middle" alt="instagram logo" /></a>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="copyright">
                    <p style={{ color: '#A5BEC9', fontSize: 12 }}>© Society of Student Affairs 2022</p>
                </div>

            </Navbar>
        );
    }
}

export default footer_component;