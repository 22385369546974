import React, { Component } from 'react';
import './WhyDonate.css';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Image from 'react-bootstrap/Image'

class WhyDonate extends Component {
    constructor(props) {
      super(props);
    }

    render() {
      return (
        <Col lg={3} md={4}>
            <Row className="justify-content-center">
            <Image className = "why-donate-image disappear-if-screen-small" src={this.props.image} alt="img7" fluid />
            </Row>
            <Row className="justify-content-center">
            <p className="why-donate-text">{this.props.text}</p>
            </Row>
        </Col>
      );
    }
  }

  export default WhyDonate;