import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import NotesFAQ from '../common/NotesFAQ';
import Volunteer_Banner from './volunteer_banner';
import Volunteer_Info from './volunteer_info';
import ContributeJoinTeam from './contribute_join_team';
import ContributeDonations from './contribute_donations';
import ContributePayNow from './contribute_paynow';
import ContributeFeedback from './contribute_feedback';
import Contribute_StudyNow from './contribute_studynow';
import { animateScroll as scroll } from 'react-scroll'

class Contribute extends Component {
    constructor(props) {
        super(props);
        scroll.scrollTo(0, {duration: 500});
        this.state = {props : props};
        props.check_logged_in();
    }

    componentDidMount(){
        document.title = "SGExams | Contribute";
    }

    render() {
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <Volunteer_Banner />
                <Volunteer_Info />
                <ContributeJoinTeam />
                
                {/*
                Not accepting donations now
                <ContributeDonations />
                */}
                <Contribute_StudyNow />
                <NotesFAQ />
                {/*
                Not accepting donations now
                <ContributePayNow />
                */}
                <ContributeFeedback />
                <Footer />
            </div>
        );
    }

}

export default Contribute;
