import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import Form from 'react-bootstrap/Form' 

import DefaultProfilePicture from './img/2.png';
import './profileEdit.css';

export class Profile extends Component {
    constructor(props) {
        super(props);
        scroll.scrollTo(0, {duration: 500});

        this.state = {
            props : props,
            schoolsList: [],
            error_msg: ""
        }
        props.check_logged_in();

        this.FileUpload = React.createRef();
        this.profilePictureUploadRedirect = this.profilePictureUploadRedirect.bind(this);
        this.fetchUserData = this.fetchUserData.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateProfile = this.updateProfile.bind(this);
    }

    componentWillMount() {
        if(!this.state.props.logged_in) {
            return <Redirect to="/login" />
        } else {
            this.fetchUserData();
        }
    }

    profilePictureUploadRedirect() {
        this.FileUpload.current.click();
    }

    // Fills in the form with current user data.
    fetchUserData(){
        fetch('/api/profile').then(res => {
            try {
                res.json().then(value => {
                    // Load user info to the state if successful.
                    if (value.success) {
                        // Load the schools to be able to render their options.
                        fetch('/api/schools').then(res => {
                            try {
                                res.json().then(value1 => {
                                    this.setState({
                                        schoolsList: value1,
                                        name: value.username,
                                        gender: value.gender,
                                        email: value.email,
                                        school: value.school,
                                        bio: value.bio            
                                    })
                                })
                            } catch (err){
                                throw err;
                            }
                        })
                    }
                })
            } catch (err) {
                throw err;
            }
        })
    }

    // Updates form changes for every component
    handleChange(event) {
        const target = event.target;
        const field = target.name;

        if (field !== 'gender') {
            const value = target.value;

            this.setState({
                [field]: value
            });

        } else {
            // Since radio buttons don't have a value
            const id = target.id;

            // In case someone tries to enter something outside the options
            if (id === 'm' || id === 'f' || id === 'n') {
                this.setState({
                    gender: id
                })
            } else {
                this.setState({
                    gender: 'n'
                })
            }
        }
    }

    updateProfile(event){
        event.preventDefault();

        let data = {
            username: this.state.name,
            gender: this.state.gender,
            email: this.state.email,
            school: this.state.school,
            bio: this.state.bio,
        };


        fetch('/api/profile', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            try {
                res.json().then(value => {
                    if (value.result == "success"){
                        this.setState({success: true});
                    } else {
                        this.setState({error_msg: value.result});
                    }
                })
            } catch (err){
                throw err;
            }
        });
    }

    render() {
        if(!this.state.props.logged_in) {
            return <Redirect to="/login" />
        } else if (this.state.success){
            return <Redirect to="/profile" />
        } else {
            // Create an error message if there is one.
            let error_msg = <div></div>
            if (this.state.error_msg){
                error_msg = <div id="error" className="error">{ this.state.error_msg }</div>;
            }

            const genderIds = ["m", "f", "n"];
            const genderNames = ["MALE", "FEMALE", "OTHERS"];
            const genderInputs = [];

            for (let i=0; i < genderIds.length; i++) {
                if (genderIds[i] === this.state.gender) {
                    genderInputs.push(
                        <label key={i} className="btn gender-input-btn active">
                            <input name="gender" value={this.state.gender} onChange={this.handleChange}
                            type="radio" id={genderIds[i]} autocomplete="off"/>{genderNames[i]}
                        </label>
                    );
                } else {
                    genderInputs.push(
                        <label key={i} className="btn gender-input-btn">
                            <input name="gender" value={this.state.gender} onChange={this.handleChange}
                            type="radio" id={genderIds[i]} autocomplete="off"/>{genderNames[i]}
                        </label>
                    );
                }
            }

            return (
                <div>
                    <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>

                    <div className="profile-edit-page">
                        <form onSubmit={this.updateProfile} className="profile-edit-form">
                            <div className="profile-picture-input-container">
                                <img src={ DefaultProfilePicture } alt="defaultProfilePicture"/>
                                {/*
                                <p onClick={this.profilePictureUploadRedirect}>Edit</p>
                                <input type="file" id="customFile" ref={this.FileUpload} accept="image/png, image/gif, image/jpeg"/>
                                */}
                            </div>


                            <div className="row">
                                <div className="form-group col-xl-8 col-lg-7 col-6">
                                    <label for="nameInput">Username</label>
                                    <input name="name" value={this.state.name} onChange={this.handleChange}
                                    type="text" className="form-control" id="nameInput"/>
                                </div>

                                <div className="form-group col gender-input-container">
                                    <div className="btn-group-toggle" data-toggle="buttons">
                                        {genderInputs}
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label for="emailInput">Email</label>
                                <input name="email" value={this.state.email} onChange={this.handleChange} 
                                type="email" className="form-control" id="emailInput"/>
                            </div>
                            <Form.Group controlId="formBasicSchool">
                                <Form.Label for="schoolInput">School</Form.Label>
                                <Form.Control id="schoolInput" name="school" as="select" onChange={this.handleChange} required>
                                    {this.state.schoolsList.map((schoolData) => {
                                        if (schoolData.name === this.state.school){
                                            return <option selected>{schoolData.name}</option>
                                        } else {
                                            return <option>{schoolData.name}</option>
                                        }
                                    })}
                                </Form.Control>
                            </Form.Group>

                            <div className="form-group">
                                <label for="authorBioInput">BIO</label>
                                <input name="bio" value={this.state.bio} onChange={this.handleChange} 
                                type="text" className="form-control" aria-describedby="authorBioHelp" id="authorBioInput"/>
                                <small id="authorBioHelp" class="form-text text-muted">Your author bio will be visible on all the articles that you wrote.</small>
                            </div>

                            {error_msg}
                            <div className="profile-edit-submit-btn-container">
                                <button type="submit" className="btn profile-edit-submit-btn">SAVE CHANGES</button>
                            </div>
                        </form>
                    </div>

                    <Footer />
                </div>
            )
        }
    }
}

export default Profile;