import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './terms_and_conditions.css'

function Terms_and_conditions(){
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <a>
            <div className="link" onClick={handleShow}>
                Terms and Conditions
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                
                <Modal.Header closeButton>
                    <Modal.Title>Terms & Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body className="TnC-modal-body">
                    <section className="">
                    <div className="TnC-header">Introduction</div>
                    <p>exams.sg operates the internet platform “exams.sg”. Users can use these platforms to share study materials with other users, create and save content or exchange information in discussion forums.</p>
                    <div className="TnC-header">1. Terms and Conditions</div>
                    <p>These terms and conditions govern the contractual relationship between exams.sg and the user.</p>
                    <div className="TnC-header">2. Registration</div>
                    <p>2.1 The user registers on the platform and thereby opens an account. The user represents and warrants that he or she is at least 18 years of age and possesses the legal right capacity and ability to enter into the agreement to which these Terms apply and to use the platforms in accordance with these Terms. The data provided during registration must be true. The use of a pseudonym is permitted.</p>
                    <p>2.2 The user has to create a password for his account. The user is responsible for keeping his password secret and not allowing any third party to access his account through his password. The user is obligated to notify exams.sg immediately under the e-mail address <a className="link" href="mailto:contact@exams.sg">contact@exams.sg</a> if there are any indications that the password is used by a third party.</p>
                    <p>2.3 The user may not create a new account if exams.sg blocks his account.</p>
                    <p>2.4 exams.sg reserves the right to remove usernames if exams.sg considers it necessary to protect the rights of third parties (for example, if the owner of a trademark submits a complaint about a user which does not correspond to the real name of a user).</p>
                    <div className="TnC-header">3. Use of platforms</div>
                    <p>3.1 exams.sg only provides technical platforms for its users so that they can exchange materials and information with each other. exams.sg does not exert influence on user’s content, so long as it is not legally required.</p>
                    <p>3.2 Each user can only upload materials for all subjects and academic levels. The contents uploaded by the user are only visible to those users who have registered with exams.sg</p>
                    <p>3.3 Users may use content kept ready on exams.sg exclusively for private study and learning purposes.</p>
                    <div className="TnC-header">4. Copyrights to content</div>
                    <p>4.1 The user may only upload or post material which he himself has created or for which he has been authorized by the copyright owner to upload the material.</p>
                    <p>4.2 If and insofar as a user uploads or posts content on one of the platforms, he grants exams.sg conferrable, non-exclusive rights of usage for the uploaded content. These rights are neither limited in time nor in space. exams.sg is entitled to duplicate, reproduce, and offer this content to other users. The right also includes publication of the content. exams.sg is also entitled to use the content for other services like news feeds or subscription services. exams.sg is entitled to edit the uploaded content to an extent that is necessary to show and offer the content on the platform. exams.sg is also entitled to transfer the contents into other formats and to provide them with a watermark and/or logo of r/SGExams.</p>
                    <p>4.3 exams.sg may remove content the user has uploaded or posted to any of the platforms in part or fully without stating reasons as well as any or all of the links the user has set up.</p>
                    <p>4.4 If the User violates the intellectual property rights of others, exams.sg has the right to suspend its account.</p>
                    <div className="TnC-header">5. Restrictions</div>
                    <p>5.1 The user guarantees that all content uploaded and posted by him is in accordance with the legal requirements of the singaporean penal code, children and young persons act, personal data protection act and all intellectual property law and respects the contracts (rights of third parties) act. The user is not permitted to post any content or to set any links on websites which violate the rights of another person or the law.</p>
                    <p>5.2 Content that does not meet these requirements can be reported via e-mail to exams.sg at <a className="link" href="mailto:contact@exams.sg">contact@exams.sg</a>.</p>
                    <p>5.3 The user indemnifies exams.sg from all actual and alleged claims of third parties. This includes the costs of prosecution / legal defense, which are based on acts or omissions of the user. In particular claims based on culpable, illegal or improper use of the platforms, noncompliance with applicable regulations or a violation of the rights of the third party resulting from the users actions or approval. The user must inform exams.sg immediately in case of possible infringement.</p>
                    <p>5.4 The user may only use the platforms with the following restrictions:</p>
                    <ul>
                    <li>Users may not post any advertising.</li>
                    <li>Users are not allowed to access content or information from other users using automated mechanisms (such as bots, robots, spiders or scrapers).</li>
                    <li>Users are not allowed to upload viruses or other malicious code.</li>
                    <li>Users are not allowed to obtain registration information of another user or access an account owned by another person.</li>
                    <li>Users are not allowed to offend, intimidate or harass other users.</li>
                    <li>Users are not allowed to post content that contains hate speech, is threatening or pornographic, invites violence, or contains depictions of nudity or violence.</li>
                    <li>Users are not allowed to use the platforms for illegal, misleading, malicious or discriminatory actions.</li>
                    <li>Users are not allowed to do anything that could block or interfere with the proper functioning or appearance of the platforms, such as denial-of-service attacks, nor anything that could impede the functioning of the platforms.</li>
                    </ul>
                    <div className="TnC-header">6. Liability Limitation</div>
                    <p>6.1 The liability of exams.sg is limited as follows: exams.sg is only liable for damages in which the cause of the damage is based on an intentional or grossly negligent breach of duty by exams.sg or one of its legal representatives or vicarious agents. Furthermore, exams.sg is liable for slightly negligent breaches of essential obligations whose violation jeopardizes the attainment of the contractual purpose or for the breach of obligations which are necessary to fulfill the proper execution of the contract. exams.sg is not liable for the slightly negligent violation of obligations other than those specified in the preceding sentences.</p>
                    <p>6.2 The liability limitations stated above do not apply in case of intent, injury to life, body or health, for a defect in case of incurred guarantee for the quality of the product and for fraudulently concealed defects.</p>
                    <p>6.3 The above liability limitations do not affect the burden of proof.</p>
                    <div className="TnC-header">7. Termination</div>
                    <p>7.1 The contractual relationship may be terminated by either party at any time with immediate effect. The termination must be made in writing (via the platform or by e-mail).</p>
                    <p>Users can terminate a contract with exams.sg via e-mail to <a className="link" href="mailto:contact@exams.sg">contact@exams.sg</a>.</p>
                    <div className="TnC-header">8. Modification of these terms and conditions</div>
                    <p>8.1 exams.sg reserves the right to make changes to these terms and conditions in accordance with section 8.2, as far as these are reasonable for the user. exams.sg will announce changes to these terms and conditions to the user with an appropriate deadline, e.g. by means of a message to the account inbox or via the user's e-mail address stored at the time of registration, and change the current version available on the platform accordingly. If the user does not object to the validity of the new terms and conditions within one month after notification and continues to use the services provided by exams.sg, the amended terms and conditions shall be deemed accepted. In the notification, exams.sg will point out to the user the right to objection and the importance of the objection period.</p>
                    <p>8.2 exams.sg reserves the right to change the terms of business in the following cases:</p>
                    <ul>
                    <li>if the change is only beneficial to the user;</li>
                    <li>if the change is purely technical or procedural, unless it has a significant impact on the user;</li>
                    <li>Insofar as exams.sg is obliged to establish the conformity of the terms and conditions with applicable law, in particular if the relevant laws or their interpretation are changed by the courts;</li>
                    <li>Insofar as exams.sg introduces additional, completely new services or service elements which require a description of the services in the terms of business, unless the change is disadvantageous for the user.</li>
                    </ul>
                    <p className="small">Last Updated: June 18 2019</p>
                    </section>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="CloseButton" onClick={handleClose}>
                        Sign up now!
                    </Button><br/>
                    <div className="col-12">
                        Can’t find what you’re looking for?<br/>
                        Drop us an email at <a className="link" href="mailto:hello@exams.sg">hello@exams.sg</a>, and we’ll be happy to assist!
                    </div>
                </Modal.Footer>
            </Modal>
        </a>
    )
}

export default Terms_and_conditions;
