import React, { Component } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import LIBRARYSEARCHBG from './img/1.png';
import { Redirect } from 'react-router-dom';


import './librarySearch.css';

export class LibrarySearch extends Component {
    constructor(props) {
        super(props);
        this.updateSearch = this.updateSearch.bind(this);
        this.get_search = this.get_search.bind(this);
        this.state = { searchbar : "" , redirect : false, props: props};
    }

    get_search(event){
        event.preventDefault();
        this.setState({redirect : true })
    }

    updateSearch(event){
        this.setState({[event.target.name]: event.target.value});
    }

    render() {
        if (this.state.redirect){
            this.state.redirect = false;
            return <Redirect to={"/library/notes/search/" + this.state.searchbar} />;
        }
        return (
            <div className="librarySearchContainer">
                <img className="LibrarySearchBG" src={LIBRARYSEARCHBG} alt="Library Search Background"></img>
                <div className="librarySearchCentered">
                    <div className="librarySearchHeader">Can't find the notes you're looking for?</div>
                    <div className="librarySearchContent">
                        Type in a keyword such as topic or a subject,<br/>
                        and we'll show you what we can find.
                    </div>
                    <Form onSubmit={this.get_search}>
                        <Form.Control name="searchbar" className="librarySearchTB" placeholder="🔍 Search" onChange={this.updateSearch}/>
                    </Form>
                </div>
            </div>
        )
    }
}


export default LibrarySearch;