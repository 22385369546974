import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import SubjectCard from './subComponents/subjectCard';
import Form from 'react-bootstrap/Form' 
import './libraryNotes.css';
import LibrarySearch from './librarySearch';
import LibraryFooter from './libraryFooter';

export class LibraryNotes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subjects: [],
            props: props
        }
        this.handleChange = this.handleChange.bind(this);
        this.renderCards();
    }

    renderCards() {
        if(this.props.subject == 'ONLevel') {
            fetch("/api/subject/O").then(res => {
                try {
                    res.json().then(value => {
                        this.setState({subjects: value.map((item) => 
                            <SubjectCard subject={item.name} img={"/subject_images/O-N/"+item.code+".jpg"} key={item.code} link={item.code} />
                        )});
                    })
                } catch (err){
                    throw err;
                }
            })
        } else if(this.props.subject == 'ALevel') {
            fetch("/api/subject/A").then(res => {
                try {
                    res.json().then(value => {
                        this.setState({subjects: value.map((item) => 
                            <SubjectCard subject={item.name} img={"/subject_images/A/"+item.code+".jpg"} key={item.code} link={item.code} />
                        )});
                    })
                } catch (err){
                    throw err;
                }
            })
        } else if (this.props.subject == 'IB'){
            fetch("/api/subject/IB").then(res => {
                try {
                    res.json().then(value => {
                        this.setState({subjects: value.map((item) => 
                            <SubjectCard subject={item.name} img={"/subject_images/IB/"+item.code+".jpg"} key={item.code} link={item.code} />
                        )});
                    })
                } catch (err){
                    throw err;
                }
            })
        }
        this.setState();
    }
    
    componentDidMount() {
        document.title = 'SGExams | Library Notes';
    }

    handleChange(event) {
        this.state.props.changeSubject(event.target.value);
        this.renderCards();
    }

    render() {
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <LibrarySearch />
                <div className="library-notes-cards">
                    <select className="form-control level-select-dropdown" aria-label="select level" 
                    value={this.props.subject} onChange={this.handleChange}>
                        <option selected hidden value={this.state.subject}>{this.state.subject}</option>
                        <option value="ONLevel">O / N Level</option>
                        <option value="ALevel">A-Level</option>
                        <option value="IB">IB</option>
                    </select>

                    <h4 className="subjects-header-title">Popular subjects</h4>

                    <div className="d-flex flex-wrap row">
                        {this.state.subjects}
                    </div>
                </div>
                <LibraryFooter />
                <Footer />
            </div>
        )
    }
}

export default LibraryNotes;
