import React, { Component } from 'react';
import DownArrow from '../arrowblack.png';
import './NotesFAQ.css';
import {Row, Col, Card, Accordion } from 'react-bootstrap';

function QuestionCard(props) {
    return (
        <Card className="mb-2">
            <Accordion.Toggle as={Card.Header} eventKey={props.eventKey}>
            <Row>
                <Col className="faq-header">{props.header}</Col>
                <Col xs="auto" className="text-left">
                    <img className="down-arrow" src={DownArrow} alt=""/>
                </Col>
            </Row>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={props.eventKey}>
                <Card.Body>{props.children}</Card.Body>
            </Accordion.Collapse>
        </Card>
    );
}

class NotesFAQ extends Component {
    render(){
      return (
          <div className="faq">
            <p className="mx-2">Frequently Asked Questions (FAQ)</p>
            <Accordion>
                <QuestionCard eventKey="0" header="How can I trust the notes on the website?">
                    <p className="">Our notes are looked through by our academic Curators who ensure that the notes are up-to-date and accurate. Our Curators are alumni who have achieved at least an ‘A’ in the subject that they are curating. They remove spam and irrelevant notes from our repository.</p>
                </QuestionCard>
                <QuestionCard eventKey="1" header="What are the file formats accepted and what is the maximum file upload size?">
                    <p>Currently, we only accept PDF files, up to a maximum of 30MB.</p>
                </QuestionCard>
                <QuestionCard eventKey="2" header="Must the notes I upload be typed out?">
                    <p>You can upload notes that are presented in pretty much any format! For example, you can upload your mind maps, your handwritten notes, the notes that you’ve typed out on your laptops etc.</p>
                    <p>Unfortunately, we only accept PDF uploads for now. As such, for handwritten notes, you may take pictures, or scan them using a scanner, and compile them into a PDF document.</p>
                    <p>There are free online converters you can use to convert your pictures into a PDF. One such JPEG to PDF converter you can use is <a href="https://jpg2pdf.com/">https://jpg2pdf.com/</a>. There are many more free online converters out there which you can use; Google for them!</p>
                </QuestionCard>
                <QuestionCard eventKey="3" header="Can I upload notes from my school, tuition centre or textbooks?">
                    <p>No. Please do not upload notes created by your school, your tuition centres, or any published textbooks.</p>
                    <p>Please note that you are not allowed to upload notes that are the works or intellectual properties of others without their explicit permission. These include:
                        <ul>
                            <li>Textbooks</li>
                            <li>School notes</li>
                            <li>Notes from tuition centres</li>
                            <li>Notes created by other students, which you have not obtained permission to freely distribute</li>
                        </ul>
                    </p>
                    <p>Materials which fit into these categories will be removed by our team of Curators and Academic Heads.</p>
                    <p>Users who are repeat offenders will be banned from the usage of our site.</p>
                    <p>While we wish to create a community of students helping one another, we hope to respect the intellectual property rights of others, and keep this platform a safe, legal and fair space for all to contribute.</p>
                    <p>If you find your work published on this site without your permission, do drop us an email at <a href="mailto:hello@exams.sg">hello@exams.sg</a>, along with evidence that you own the set of resources. We will respond to your takedown request as soon as we can.</p>
                </QuestionCard>
                {/*
                <QuestionCard eventKey="4" header="Why are there advertisements on exams.sg?">
                    <p>We are incorporating non-intrusive advertisements on the website to offset the costs of running the website for all students in Singapore. Alternatively, we welcome donations to support us in our activities :)</p>
                </QuestionCard>
                */}
            </Accordion>
          </div>
      );
    }
  }
  
  export default NotesFAQ;
  