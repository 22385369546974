import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Card, Col, Row, Image } from 'react-bootstrap';
import './notesCard.css';
import fileIcon from '../img/file.png';

function NotesCard(props) {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const upvote = (event) => {
        fetch('/api/vote/upvote', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({listing_id: props.data.id})
        }).then(res => {
            props.reload();
        });
        event.preventDefault();
    };

    const removeUpvote = (event) => {
        fetch('/api/vote/remove', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({listing_id: props.data.id})
        }).then(res => {
            props.reload();
        });
        event.preventDefault();
    };

    const files = [];
    for (let i = 0; i < props.data.files.length; i++) {
        files.push(
            <a href={props.data.files[i].file_link} className="file col col-lg-6"><Image src={fileIcon} className="file_image"></Image><div className="file_name">{props.data.files[i].file_name}</div></a>
        )
    }

    const tags = [];
    for (let i = 0; i < props.data.categories.length; i++) {
        tags.push(
            <Button className="notes-tag" variant="secondary" size="sm" disabled>{props.data.categories[i]}</Button>
        )
    }
    for (let i = 0; i < props.data.levels.length; i++) {
        tags.push(
            <Button className="notes-tag" variant="secondary" size="sm" disabled>{props.data.levels[i]}</Button>
        )
    }
    var upvoteButton = <Button variant="success" className="notes-modal-voting-btn" onClick={upvote}>UPVOTE</Button>;
    if (props.data.votable === 'voted') {
        upvoteButton = <Button variant="danger" className="notes-modal-voting-btn" onClick={removeUpvote}>REMOVE</Button>;
    } else if (props.data.votable === 'login'){
        upvoteButton = <Button variant="secondary" className="notes-modal-voting-btn" disabled>LOGIN TO UPVOTE</Button>;
    }

    return (
        <a>
            <div className="link" onClick={handleShow} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Card className="librarySubjectCard">
                    <Card.Body>
                        <Row>
                            <Col lg={8}>
                                <Card.Title>{props.data.title}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted notes-card-description">{props.data.description}</Card.Subtitle>
                            </Col>
                            <Col lg={4}>
                                <Card.Subtitle className="mb-2 text-muted">{props.data.votes} upvotes</Card.Subtitle>
                                <Row>
                                    <Card.Subtitle className="mb-2 text-muted">Uploaded by {props.data.username},  created {props.created_at_date}</Card.Subtitle>
                                </Row>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title style={{ fontWeight: '600' }}>{props.data.title}</Modal.Title>
                </Modal.Header>

                <Modal.Body className="librarySubjectModalBody">
                    <div className="row notes-modal-details">
                        <div className="col-sm-8 notes-modal-description">
                            {props.data.description}
                        </div>

                        <div className="col-sm-4">
                            <div className="files row">
                                {files}
                            </div>
                        </div>
                    </div>
                    <div className="notes-modal-tags">
                        {tags}
                    </div>
                    <div className="row notes-modal-voting">
                        <div className="col-md-6">
                            {upvoteButton}
                            <span>{props.data.votes} upvotes</span>
                        </div>

                        <div className="col-md-6">
                            <div className="librarySubjectModalByWho">Uploaded by {props.data.username}</div>
                            <div className="librarySubjectModalDate">Created {props.created_at_date}</div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal>
        </a>
    )
}

export default NotesCard;