import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { Redirect } from 'react-router-dom';
import './register.css'
import Form from 'react-bootstrap/Form' 
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Terms_and_conditions from './terms_and_conditions'
import Privacy_policy from './privacy_policy'
import { Link } from "react-router-dom";
import { animateScroll as scroll } from 'react-scroll'

class register extends Component {
    constructor(props) {
        super(props);
        this.state = { props : props, username: "", email: "" , school: "", gender: "", password: "" , password_confirm: "", schools: []};
        this.handleChange = this.handleChange.bind(this);
        this.register = this.register.bind(this);
        scroll.scrollTo(0, {duration: 500});
        props.check_logged_in();
    }

    componentDidMount(){
      document.title = "SGExams | Register";
    }

    componentWillMount() {
        if(this.state.props.logged_in) {
            return <Redirect to="/library" />
        } else {
            this.get_schools();
        }
    }

    get_schools(){
        fetch("/api/schools")
          .then(res => {
            try {
                res.json().then(value => {
                    this.setState({ schools: value });
                })
            } catch (err){
                throw err;
            }
        })
    }

    handleChange(event){
        this.setState({[event.target.name]: event.target.value});
    }

    register(event){
        let data = {};
        data['username'] = this.state['username'];
        data['email'] = this.state['email'];
        data['school'] = this.state['school'];
        data['gender'] = this.state['gender'];
        data['password'] = this.state['password'];
        fetch('/api/register', {
            method: 'POST',
            headers:{
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(res => {
            try {
                res.json().then(value => {
                    if (res.status == 200){
                        this.setState({success: true});
                    } else {
                        this.setState({error_msg: value.result});
                    }
                })
            } catch (err){
                throw err;
            }
        });
        event.preventDefault();
    }

    render(){
        let error_msg = null, submit;
        if (this.state.error_msg){
            error_msg = <div id="error" className="error col-md-9">{ this.state.error_msg }</div>;
        }
        if (this.state['password'] != this.state['password_confirm']){
            submit = <Button className="register-submit" type="submit" disabled>Let's go!</Button>;
            error_msg = <div id="error" className="error col-md-9">Password and Confirm Password must be the same.</div>;
        } else {
            submit = <Button className="register-submit" type="submit">Let's go!</Button>;
        }
        const school_options = [];
        for (var i = 0; i < this.state.schools.length; i++){
            school_options.push(
                <option className="school_option" value={ this.state.schools[i].id }>
                    { this.state.schools[i].name }
                </option>
            );
        }

        const handleClose = () => this.setState({success: false});

        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <div className="page-container register-background">
                    <div className='register-form col-md-8'>
                        <h2 className='register-header'>Join the largest student community in Singapore.</h2>
                        <Form onSubmit={this.register} className="register-form-content">
                            <Form.Group controlId="formBasicUsername">
                                <Form.Label className="input-label">Username</Form.Label>
                                <Form.Control name="username"  className="adjustFormInput col-md-9" type="text" placeholder="@ExamsIsFun" onChange={this.handleChange} required/>
                                <Form.Text className="text-muted">You will be identified by other community members via your username for all your activities on SGExams.</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label className="input-label">Email</Form.Label>
                                <Form.Control name="email"  className="adjustFormInput col-md-9" type="email" placeholder="examisfun@example.com" onChange={this.handleChange} required/>
                                <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
                            </Form.Group>
                            <Form.Group controlId="formBasicSchool">
                                <Form.Label className="input-label">School</Form.Label>
                                <Form.Control name="school" as="select" className="adjustFormInput col-md-9" onChange={this.handleChange} required>
                                    <option className="school_option" value="" selected disabled hidden>
                                        Select School
                                    </option>
                                    <option className="school_option" value="">
                                        Not Applicable
                                    </option>
                                    { school_options }
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formBasicGender">
                                <Form.Label className="input-label">Gender</Form.Label>
                                <Form.Control name="gender" as="select" placeholder ="Select Gender" className="adjustFormInput col-md-9" onChange={this.handleChange} required>
                                    <option className="gender_option" value="" selected disabled hidden>
                                        Select Gender
                                    </option>
                                    <option className="gender_option" value="m">
                                        Male
                                    </option>
                                    <option className="gender_option" value="f">
                                        Female
                                    </option>
                                    <option className="gender_option" value="n">
                                        Others
                                    </option>
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="formBasicPassword">
                                <Form.Label  className="input-label">Password</Form.Label>
                                <Form.Control name="password" className="adjustFormInput col-md-9" type="password" placeholder="********" onChange={this.handleChange} required/>
                            </Form.Group>
                            <Form.Group controlId="formBasicPasswordConfirm">
                                <Form.Label  className="input-label">Confirm Password</Form.Label>
                                <Form.Control name="password_confirm" className="adjustFormInput col-md-9" type="password" placeholder="********" onChange={this.handleChange} required/>
                            </Form.Group>
                            { error_msg }
                            <div className="description">By signing up, you agree to our <Terms_and_conditions/> and <Privacy_policy privacy_policy = {this.state.props.privacy_policy}/>.</div>
                            <div className="register-button-container col-md-9">
                                { submit }
                            </div>
                            
                            <Modal show={ this.state.success } onHide={ handleClose } size="sm">
                                <Modal.Body className="popup_modal">
                                    <div className="success-header">
                                        Sign up successful
                                    </div>
                                    Your account has been created, login to continue.
                                </Modal.Body>
                                <Link to="login" className="link">
                                    <Modal.Footer>
                                        <div className="align-center">Login!</div>
                                    </Modal.Footer>
                                </Link>
                            </Modal>
                        </Form> 
                        <div className="login col-md-9">
                            <hr/>
                            Have an account? &nbsp;
                            <Link className="link" to="login">Login</Link>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default register;
