import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { animateScroll as scroll } from "react-scroll";
import img0 from './images/0.png';
import './notFound.css';


class NotFound extends Component {
  constructor(props) {
    super(props);
    scroll.scrollTo(0, { duration: 500 });
    this.state = {props : props};
    props.check_logged_in();
  }

  componentDidMount() {
    document.title = 'SGExams | Not Found';
  }

  render() {
    return (
      <div>
        <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
        <Row className="justify-content-center">
            <Image className = "not-found-image" src={img0} alt="img0" fluid />
        </Row>
        <Row className="justify-content-center">
            <p className = "not-found-text body-font-size">
                Uh oh, what you're looking for doesn't seem to be here.
            </p>
        </Row>
        <Row className="justify-content-center">
            <Col className = "button-wrapper" md="auto" lg="2">
                <Button className = "not-found-button" variant="sgexams-dark" href="/library">Back to Library</Button>
            </Col>
            <Col className = "button-wrapper" md="auto" lg="2">
                <Button className = "not-found-button" variant="sgexams-dark" href="/upload">Donate more notes</Button>
            </Col>
        </Row>
        <Footer />
      </div>
    );
  }
}

export default NotFound;
