import React, { Component } from 'react';
import Navbar from '../../common/navbar/Navbar';
import Footer from '../../common/footer/Footer';
import './librarySearchNotes.css';
import LibrarySearch from '../librarySearch';
import LibraryFooter from '../libraryFooter';
import { Card, Col, Row } from 'react-bootstrap';
import NotesCard from '../subComponents/notesCard';
import { animateScroll as scroll } from "react-scroll";
import { withRouter } from "react-router";
import { Redirect } from 'react-router-dom';

export class LibrarySearchNotes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            searchQuery: "",
            searchResult: [],
            loading: true,
            props : props,
            redirect: ""
        }
        props.check_logged_in();
        this.state.searchQuery = this.props.match.params.query;
        scroll.scrollTo(0, {duration: 500});
        this.retrieve_search_result();
        var title = 'SGExams | Library Notes | Search Result';
        document.title = title;
        this.handleChange = this.handleChange.bind(this);
        this.reload = this.reload.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.match.params !== this.props.match.params) {
            this.state.searchQuery = this.props.match.params.query;
            this.setState({loading:true});
            this.retrieve_search_result();
        }
    }

    handleChange(event) {
        this.setState({ [event.target.name]: [event.target.value] });
    }

    reload(){
        this.retrieve_search_result();
    }

    retrieve_search_result() {
        var requestURL = '/api/search/' + this.state.searchQuery;
        fetch(requestURL).then(res => {
            try {
                res.json().then(value => {
                    if (value[0] != "result not found") {
                        this.setState({ searchResult: value });
                    }
                    this.setState({ loading : false });
                })
            } catch (err) {
                throw err;
            }
        })

    }

    // if response returned notes of subjects, then render the notes row. Otherwise, will render empty notes response.
    checkIfSearchResultIsEmpty() {
        if (this.state.loading) return this.renderLoadingSearchResult();
        return this.renderSearchResult().length > 0 ? this.renderSearchResult() : this.renderEmptySearchResult();
    }

    // renders empty notes response
    renderLoadingSearchResult() {
        return <div>
            <div className="status-result">
                Loading...
            </div>
        </div>
    }

    // renders empty notes response
    renderEmptySearchResult() {
        return <div>
            <div className="status-result">
                No relevant notes found.
            </div>
        </div>
    }

    // renders subject notes
    renderSearchResult() {
        var searchResultRender = this.state.searchResult.map((data, id) => {
            let newDateFormat = new Date(data.created_at);
            let created_at_year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(newDateFormat);
            let created_at_month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(newDateFormat);
            let created_at_day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(newDateFormat);
            let created_at_date = created_at_day + '/' + created_at_month + '/' + created_at_year;
            return <div key={id}>
                <Col lg={12}>
                    <NotesCard data={data} created_at_date = {created_at_date}  reload={this.reload}/>
                </Col>
            </div>
        })
        return searchResultRender;
    }

    render() {
        // call method to render notes if any
        var searchResult = this.checkIfSearchResultIsEmpty();
        return (
            <div>
                <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
                <LibrarySearch parentSearch={this.search}/>
                <div className="search-results-content">
                    <div className="search-results-header">
                        Search results for: {this.state.searchQuery}
                    </div>
                    { searchResult }
                </div>
                <LibraryFooter />
                <Footer />
            </div>
        )
    }
}

export default withRouter(LibrarySearchNotes);
