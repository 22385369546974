import React, { Component } from 'react';
import Navbar from '../common/navbar/Navbar';
import Footer from '../common/footer/Footer';
import { Button } from 'react-bootstrap';
import { animateScroll as scroll } from "react-scroll";
import Form from 'react-bootstrap/Form' 
import { Redirect , Link} from "react-router-dom";
import './uploadNotes.css';
import Terms_and_Conditions from './terms_and_conditions';
import axios from 'axios';

class UploadNotes extends Component {
  constructor(props) {
    super(props);
    scroll.scrollTo(0, { duration: 500 });
    this.handleChange = this.handleChange.bind(this);
    this.upload = this.upload.bind(this);
    props.check_logged_in();
    this.state = {
      username: props.username,
      subject: "Select subject",
      subjects: [],
      title: "",
      year: "Select year",
      tags: "",
      matchingTags: [],
      description: "",
      checked: false,
      uploadedFiles: [],
      success: false,
      error_msg: "",
      props : props
    }
    this.tags = ["practice", "notes", "summary", "secondary 1", "secondary 2", "secondary 3", "secondary 4", "junior college 1", "junior college 2"];
  }

  componentWillMount(){
    if (!this.state.props.logged_in){
      return <Redirect to="/login" />;
    } else {
      this.get_subjects();
    }
  }

  componentDidMount() {
    document.title = 'SGExams | Upload';
  }

  get_subjects(){
    fetch("/api/subject").then((res) => {
      if (!res.ok) {
        throw Error("Error fetching from Express API");
      } else {
        try {
          res.json().then((value) => {
            this.setState({ subjects: value });
          });
        } catch (err) {
          throw err;
        }
      }
    });
    
  }

  splitAtLastComma(string) {
    // returns the string till the last comma, and the characters after the last comma
    var arr = string.split(",")
    var last_characters = arr[arr.length - 1].replace(" ", "")
    var left_string = ""
    for (let i = 0; i < arr.length - 1; i++) {
      left_string += arr[i]
      left_string += ","
    } 
    return [left_string, last_characters]
  }

  handleChange(event) {
    if (event.target.name == "tags") {
      this.state.matchingTags = []
      let searchBarText = event.target.value
      var result = this.splitAtLastComma(searchBarText)
      var left_string = result[0]
      var last_characters = result[1]
      for (let i = 0; i < this.tags.length; i++) {
        if (this.tags[i].includes(last_characters)) {
          var find = false;
          for(let j = 0; j < this.state.tags.split(',').length; j++){
            if (this.state.tags.split(',')[j].trim() == this.tags[i]) find = true;
          }
          if (!find) this.state.matchingTags.push(this.tags[i]);
        }
      }
    } else if (event.target.name.includes("tagged-button")) {
      var taggedName = event.target.name.replace("tagged-button", "")
      var currentTags = this.state.tags
      var result = this.splitAtLastComma(currentTags)
      var left_string = result[0]
      var last_characters = result[1]
      this.setState({"tags": left_string + taggedName + ","});
      this.state.matchingTags = []
    } else if (event.target.name == "checked") {
      this.setState({[event.target.name]: !this.state.checked});
    } else if (event.target.name == "uploadFile"){
      for(let i = 0; i < event.target.files.length; i++){
        let found = false;
        for(let j = 0; j < this.state.uploadedFiles.length; j++) {
          if (this.state.uploadedFiles[j].name == event.target.files[i].name) {
            found = true;
          }
        }
        if(!found) this.state.uploadedFiles.push(event.target.files[i]);
      }
    } else if (event.target.name == "deleteFile"){
      for(let i = 0; i < this.state.uploadedFiles.length; i++){
        if (this.state.uploadedFiles[i].name == event.target.value) {
          this.state.uploadedFiles.splice(i, 1);
        }
      }
    } 
    this.setState({[event.target.name]: event.target.value}); 
  }

  upload(event){
    event.preventDefault();
    const formData = new FormData();
    this.state.uploadedFiles.forEach(file => {
      formData.append("files", file)
    })
    formData.append('title',this.state.title);
    formData.append('year',this.state.year);
    formData.append('tags',this.state.tags);
    formData.append('description',this.state.description);
    formData.append('subject',this.state.subject);
    const config = {
      headers:{
        'Content-Type': 'multi-part/form-data'
      }
    }
    axios.post('/api/upload', formData, config).then(res => {
      if (res.data.success){
          this.setState({success: true});
      } else {
          this.setState({error_msg: res.data.error});
      }
    });
  }

  render() {
    if (!this.state.props.logged_in){
      return <Redirect to="/login" />;
    } else if (this.state.success){
      return <Redirect to="/notesSubmitted" />;
    } else {
      let error_msg = null;
      if (this.state.error_msg){
          error_msg = <div id="error" className="error-upload">{ this.state.error_msg }</div>;
      }
      const subjects = [];
      for(let i = 0; i < this.state.subjects.length; i++){
        subjects.push(
          <option key={this.state.subjects[i].name} value={this.state.subjects[i].name}>{this.state.subjects[i].name}</option>
        )
      }
      
      const files = [];
      for(let i = 0; i < this.state.uploadedFiles.length; i++){
        files.push(
          <Button variant="outline-danger" key={this.state.uploadedFiles[i].name} name="deleteFile" className="deleteFileButton" value={this.state.uploadedFiles[i].name} onClick = {this.handleChange}>
            &#10005; {this.state.uploadedFiles[i].name}
          </Button>
        )
      }
      const label = <div>I agree to <Terms_and_Conditions/></div>

      return (
        <div>
          <Navbar check_logged_in = {this.state.props.check_logged_in} logged_in = {this.state.props.logged_in}/>
          <div className="upload-page">
            <div className="upload-background">
              <div className='upload-form col-md-6'>
                  <h2 className='upload-header'>Upload Your Notes</h2>
                  <Form onSubmit={this.upload} className="upload-form-content" encType="multipart/form-data">
                      <Form.Group>
                          {/* should we not have this field? or make it readOnly? ideally they should be logged in before they can even upload */}
                          <Form.Control name="username"  className="uploadFormInput" type="username" value = {this.state.username} onChange={this.handleChange} readOnly/>
                      </Form.Group>
                      <Form.Group>
                      <Form.Control name = "subject" className="uploadFormInput" as="select" value={this.state.subject} onChange = {this.handleChange} custom required>
                        {/* need to have some api at the back to render all the options */}
                        <option selected hidden value="">Select subject</option>
                        {subjects}
                      </Form.Control>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control name = "year" className="uploadFormInput" as="select" value={this.state.year} onChange = {this.handleChange} custom required>
                          <option selected hidden value="">Select year</option>
                          <option value="2010">2010</option>
                          <option value="2011">2011</option>
                          <option value="2012">2012</option>
                          <option value="2013">2013</option>
                          <option value="2014">2014</option>
                          <option value="2015">2015</option>
                          <option value="2016">2016</option>
                          <option value="2017">2017</option>
                          <option value="2018">2018</option>
                          <option value="2019">2019</option>
                          <option value="2020">2020</option>
                          <option value="2021">2021</option>
                          <option value="2022">2022</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group>
                          <Form.Control autoComplete="off" name = "title" type = "text" className="uploadFormInput" value = {this.state.title}placeholder="Title of your notes" onChange={this.handleChange} required/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control autoComplete="off" name = "tags" type = "input" className="uploadFormInput" placeholder="Tags (you may choose more than one)" onChange={this.handleChange} value = {this.state.tags}/>
                        <div className = "tagged-suggestions">
                          {this.state.matchingTags.map(x => <Button className = "tagged-button" name={"tagged-button" + " " + x} variant="sgexams-dark" onClick = {this.handleChange}>{x}</Button>)}
                        </div>
                      </Form.Group>
                      <Form.Group>
                          <Form.Control as="textarea" rows = {5} autoComplete="off" name = "description" type = "input" className="uploadFormInput" placeholder="Briefly describe what your notes consist of; who these notes are for, what syllabus it is modelled after, etc." onChange={this.handleChange} value = {this.state.description} required/>
                      </Form.Group>
                      <Form.Group>
                        <Form.Control name="uploadFile" id="uploadFile" className="uploadFile" type="file" onChange={this.handleChange} multiple accept=".pdf" required/>
                        <Form.Label for="uploadFile" className="uploadFileLabel">Choose file(s) to upload.</Form.Label>
                        {files}
                      </Form.Group>
                      { error_msg }
                      <div className="row group-submit">
                          <div className="col mt-2">
                            <Form.Group controlId="formBasicCheckbox">
                              <Form.Check name="checked" id="terms-conditions-checkbox" className="terms-conditions-checkbox" type="checkbox" checked = {this.state.checked} onChange={this.handleChange} label={label} required />
                              </Form.Group>
                          </div>
                          <div className="col">
                            <Button className="upload-submit" type="submit">Upload</Button>
                          </div>
                      </div>
                  </Form>
                </div>
              </div>
            </div>
          <Footer />
        </div>
      );
    }
  }
}

export default UploadNotes;
